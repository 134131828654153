<template>
	<f7-page id="signup">
		<f7-navbar title="Sign up" back-link="Back" back-link-force />
		<div class="p-3">
			<ValidationObserver ref="validator">
				<ValidationProvider
					v-slot="{ errors }"
					name="name"
					rules="required"
				>
					<text-input
						v-model="name"
						type="text"
						name="name"
						placeholder="Name"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					name="phone"
					rules="required|mobile:IN"
				>
					<text-input
						v-model="mobile"
						type="number"
						name="phone"
						placeholder="Mobile Number"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					name="email"
					rules="email"
				>
					<text-input
						v-model="email"
						type="text"
						name="email"
						placeholder="Email"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					name="password"
					rules="required|min:8|max:16|password"
				>
					<text-input
						v-model="password"
						type="password"
						name="password"
						placeholder="Password"
						:tooltip="passwordComplexityMsg()"
						:error="errors[0]"
						:events="{
							focus: showTooltip,
							blur: hideTooltip
						}"
					/>
				</ValidationProvider>
				<f7-button
					class="display-flex elevation-4 p-3 mb-3"
					style="height: 54px; background: white"
					@click="connectSocialPlatform('google')"
				>
					<p class="w-100 m-0">
						<f7-row class="align-items-center" no-gap>
							<f7-col width="20">
								<img src="/images/google.svg" alt="Google" class="display-flex">
							</f7-col>
							<f7-col width="60">
								<span class="float-left font-weight-normal text-capitalize text-color-gray" style="font-size: 18px">
									Connect{{ google ? 'ed' : '' }} Google
								</span>
							</f7-col>
							<f7-col width="20">
								<f7-icon
									:f7="google ? 'checkmark_alt' : 'arrow_right'"
									class="float-right"
									:color="google ? 'green' : 'gray'"
								/>
							</f7-col>
						</f7-row>
					</p>
					<ValidationProvider
						v-slot="{ errors }"
						name="gp_user_id"
						tag="small"
						class="position-absolute text-color-red"
						style="left: 75px; top: 26px; font-weight: initial; text-transform: initial"
					>
						{{ errors[0] }}
					</ValidationProvider>
				</f7-button>
				<f7-button
					class="display-flex elevation-4 p-3 mb-3"
					style="height: 54px; background: white"
					@click="connectSocialPlatform('facebook')"
				>
					<p class="w-100 m-0">
						<f7-row class="align-items-center" no-gap>
							<f7-col width="20">
								<f7-icon f7="logo_facebook" class="float-left" style="color: #3b5998" />
							</f7-col>
							<f7-col width="60">
								<span class="float-left font-weight-normal text-capitalize text-color-gray" style="font-size: 18px">
									Connect{{ facebook ? 'ed' : '' }} Facebook
								</span>
							</f7-col>
							<f7-col width="20">
								<f7-icon
									:f7="facebook ? 'checkmark_alt' : 'arrow_right'"
									class="float-right"
									:color="facebook ? 'green' : 'gray'"
								/>
							</f7-col>
						</f7-row>
					</p>
					<ValidationProvider
						v-slot="{ errors }"
						name="fb_user_id"
						tag="small"
						class="position-absolute text-color-red"
						style="left: 75px; top: 26px; font-weight: initial; text-transform: initial"
					>
						{{ errors[0] }}
					</ValidationProvider>
				</f7-button>
			</ValidationObserver>
			<f7-button
				large
				raised
				fill
				:disabled="processing"
				@click="signUp"
			>
				Create Account
				<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
			</f7-button>
			<p class="text-align-center mb-0">
				By creating an account,
			</p>
			<p class="text-align-center m-0">
				I accept the
				<f7-link
					href="/terms"
					class="underline"
				>
					Terms &amp; Conditions
				</f7-link>
			</p>
		</div>
		<otp-verification
			type="signup"
			:show="showOtpVerification"
			:mobile-number="mobile"
			:otp-value="otp"
			@closed="showOtpVerification = false"
		/>
	</f7-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextInput from '~/components/text-input'
import OtpVerification from '~/components/otp-verification'

export default {
	components: {
		TextInput,
		OtpVerification,
		ValidationObserver,
		ValidationProvider
	},
	props: {
		gp_data: { // eslint-disable-line
			type: Object,
			default: null
		},
		fb_data: { // eslint-disable-line
			type: Object,
			default: null
		}
	},
	data () {
		return {
			name: '',
			mobile: '',
			email: '',
			password: '',
			google: null,
			facebook: null,
			showOtpVerification: false,
			signingUp: false,
			otp: null
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	beforeMount () {
		this.mobile = this.$f7route.query.mobile || ''

		if (this.gp_data) {
			this.name = this.gp_data.name
			this.email = this.gp_data.email
			this.google = Object.assign({}, this.gp_data)
		}

		if (this.fb_data) {
			this.name = this.fb_data.name
			this.email = this.fb_data.email
			this.facebook = Object.assign({}, this.fb_data)
		}
	},
	mounted () {
		if (this.$bridge.getName() === 'WEB') {
			this.initGoogleSdk()
			this.initFacebookSdk()
		}

		window.emitSocialPlatform = this.emitSocialPlatform
	},
	methods: {
		async signUp () {
			if (await this.$refs.validator.validate()) {
				this.processing = true
				this.$store.dispatch('register', {
					name: this.name,
					phone: this.mobile,
					email: this.email,
					password: this.password,
					gp_user_id: this.google ? this.google.id : null,
					gp_data: this.google,
					fb_user_id: this.facebook ? this.facebook.id : null,
					fb_data: this.facebook
				}).then((response) => {
					this.processing = false

					if (response.code === 201) {
						this.$store.dispatch('sendEmail', {
							to: [this.email],
							subject: 'Confirm Your Email Address'
						})

						if (!response.data.info.is_phone_verified) {
							this.$store.dispatch('sendOtp', { phone: this.mobile })
							this.otp = response.data.otp || null
							this.showOtpVerification = true
						} else {
							this.$f7router.navigate('/home', {
								clearPreviousHistory: true
							})
						}
					} else {
						this.$f7.dialog.alert('Something went wrong. Please try again later', 'Oops!')
					}
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			}
		},
		connectSocialPlatform (platform) {
			if (this[platform] === null) {
				const data = {
					name: platform,
					type: 'signup'
				}

				this.processing = true
				this.$bridge.connectSocialPlatform(
					this.$bridge.getName() === 'ANDROID' ? JSON.stringify(data) : data
				)
			}
		},
		emitSocialPlatform (platform) {
			if (typeof platform === 'string') {
				platform = JSON.parse(platform)
			}

			if (platform.type === 'signup') {
				if (platform.data) {
					this[platform.name] = platform.data
				} else {
					this.$f7.toast.create({
						text: `Sorry! we couldn't connect to your ${platform.name} account.`,
						closeTimeout: 3000
					}).open()
				}
			}

			this.processing = false
		}
	}
}
</script>

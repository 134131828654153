<template>
	<f7-page id="intro">
		<f7-swiper style="height: 420px">
			<f7-swiper-slide class="pt-0">
				<div class="text-align-center p-4" style="background: linear-gradient(to bottom, #39C0EE, #0AA4DA)">
					<img src="/images/delivery-boy.png" alt="delivery-boy" height="283px" width="277px">
				</div>
				<h1 class="text-align-center my-3 px-5" style="line-height: 1">
					Delivered quickly to your doorstep
				</h1>
			</f7-swiper-slide>
			<!-- <f7-swiper-slide class="pt-0">
				<div class="text-align-center p-4" style="background: linear-gradient(to bottom, #39C0EE, #0AA4DA)">
					<img src="/images/delivery-boy.png" alt="delivery-boy" height="283px" width="277px">
				</div>
				<h1 class="text-align-center my-3 px-5" style="line-height: 1">
					Delivered quickly to your doorstep
				</h1>
			</f7-swiper-slide>
			<f7-swiper-slide class="pt-0">
				<div class="text-align-center p-4" style="background: linear-gradient(to bottom, #39C0EE, #0AA4DA)">
					<img src="/images/delivery-boy.png" alt="delivery-boy" height="283px" width="277px">
				</div>
				<h1 class="text-align-center my-3 px-5" style="line-height: 1">
					Delivered quickly to your doorstep
				</h1>
			</f7-swiper-slide> -->
		</f7-swiper>
		<small class="display-flex justify-content-center text-color-gray mb-3">
			Ready to buy sea foods online?
		</small>
		<div class="display-flex justify-content-center">
			<f7-button
				href="/locations"
				class="button-alt w-75"
				large
				raised
				fill
			>
				Set Delivery Location
			</f7-button>
		</div>
		<p class="text-align-center">
			Have an account?
			<f7-link href="/login" class="underline">
				Login
			</f7-link>
		</p>
	</f7-page>
</template>

<script>
export default {
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	mounted () {
		this.processing = process.env.NODE_ENV !== 'development'
			? !(this.getLocalStorage('afterPrecache') === 'true')
			: false

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.onmessage = (e) => {
				if (e.data === 'afterPrecache') {
					this.processing = false
					this.setLocalStorage('afterPrecache', 'true')
				}
			}
		} else {
			this.processing = false
			this.setLocalStorage('afterPrecache', 'true')
		}
	}
}
</script>

<template>
	<f7-actions
		ref="variations"
		:opened="selectedItem !== null"
		@actions:opened="lazyLoad"
		@actions:closed="selectedItem = null"
	>
		<f7-actions-group>
			<f7-actions-label>
				{{ selectedItem ? selectedItem.name : '' }}
				<f7-link
					icon-f7="xmark"
					icon-size="20px"
					class="position-absolute"
					style="top: 12px; right: 12px"
					@click="$refs.variations.close()"
				/>
			</f7-actions-label>
			<div class="py-1" style="background-color: #F5F5F5">
				<items v-if="selectedItem" :items="selectedItem.items" />
			</div>
		</f7-actions-group>
	</f7-actions>
</template>

<script>
import Items from './items'

export default {
	name: 'Variations',
	components: {
		Items
	},
	computed: {
		selectedItem: {
			get () {
				return this.$store.state.selectedItem
			},
			set (item) {
				this.$store.commit('setSelectedItem', item)
			}
		}
	}
}
</script>

<style lang="scss">
</style>

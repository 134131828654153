<template>
	<f7-page
		id="cart"
		ptr
		:ptr-distance="60"
		@ptr:refresh="refresh"
	>
		<f7-navbar title="Your Cart" back-link>
			<f7-nav-right>
				<f7-link v-if="cart.items.length > 0" icon-f7="trash" @click="emptyCart" />
			</f7-nav-right>
		</f7-navbar>
		<div v-if="cart.items.length > 0">
			<items :loading="loading" :items="cart.items" />
			<div v-if="!loading">
				<f7-list class="my-0">
					<f7-list-item
						v-if="appliedCoupon"
						:title="appliedCoupon.code"
						footer="Offer applied on the bill"
						no-chevron
					>
						<f7-icon slot="media" f7="tag_circle" color="gray" />
						<f7-link
							slot="after"
							link="#"
							icon-f7="xmark_circle_fill"
							icon-color="gray"
							@click="$store.dispatch('removeDiscount', appliedCoupon)"
						/>
					</f7-list-item>
					<f7-list-item
						v-else
						:link="isLoggedIn ? '/coupons' : '/login'"
						title="Apply Coupon"
					>
						<f7-icon slot="media" f7="tag_circle" color="gray" />
					</f7-list-item>
				</f7-list>
				<div class="bg-color-white p-3">
					<h4 class="mt-0">
						Bill Details
					</h4>
					<f7-row class="border-bottom">
						<f7-col>Item Total</f7-col>
						<f7-col>
							<span class="float-right">
								{{ cart.price.subTotal | currency }}
							</span>
						</f7-col>
					</f7-row>
					<f7-row v-if="cart.price.tax">
						<f7-col>Total Tax</f7-col>
						<f7-col>
							<span class="float-right">
								{{ cart.price.tax | currency }}
							</span>
						</f7-col>
					</f7-row>
					<f7-row v-if="cart.price.discount">
						<f7-col>Total Discount</f7-col>
						<f7-col>
							<span class="float-right">
								-{{ cart.price.discount | currency }}
							</span>
						</f7-col>
					</f7-row>
					<hr v-if="cart.price.deliveryFee" class="border-top">
					<f7-row v-if="cart.price.deliveryFee">
						<f7-col>Delivery Fee</f7-col>
						<f7-col>
							<span class="float-right">
								{{ cart.price.deliveryFee | currency }}
							</span>
						</f7-col>
					</f7-row>
					<hr class="border-top">
					<f7-row>
						<f7-col class="font-weight-bold">
							To Pay
						</f7-col>
						<f7-col>
							<span class="float-right">
								{{ cart.price.total | currency }}
							</span>
						</f7-col>
					</f7-row>
				</div>
			</div>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				Your cart is empty!
			</h2>
		</div>
		<div v-if="!loading && cart.items.length > 0 && cart.price.total > 0" class="toolbar-button-container">
			<f7-card v-if="deliveryAddress" class="m-0 mb-3">
				<f7-card-content class="p-0">
					<f7-row class="align-items-center" no-gap>
						<f7-col width="20" class="text-align-center">
							<f7-icon f7="placemark" />
						</f7-col>
						<f7-col width="80">
							<div class="py-2 pr-2" style="line-height: 1">
								<div class="display-flex justify-content-space-between align-items-center mb-2">
									<h4 class="text-capitalize m-0">
										Deliver to {{ deliveryAddress.annotation }}
									</h4>
									<f7-button small round fill @click="showAddresses = true">
										Change
									</f7-button>
								</div>
								<p class="text-color-gray m-0">
									{{ deliveryAddress.address.line }}
								</p>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
			<f7-button
				v-if="deliveryAddress"
				large
				fill
				raised
				@click="initPayments"
			>
				Proceed To Pay
			</f7-button>
			<f7-button
				v-else
				large
				fill
				raised
				:disabled="cart.items.length === 0"
				@click="isLoggedIn ? showAddresses = true : $f7router.navigate('/login')"
			>
				Add Address
			</f7-button>
		</div>
		<f7-actions
			v-if="cart.items.length > 0"
			:opened="showAddresses"
			@actions:closed="showAddresses = false"
		>
			<f7-actions-group>
				<f7-actions-label>
					Choose Delivery Address
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						class="position-absolute"
						style="top: 12px; right: 12px"
						@click="showAddresses = false"
					/>
				</f7-actions-label>
				<div class="p-3" style="background-color: #F5F5F5">
					<f7-card
						v-for="(address, index) in addresses"
						:key="index"
						class="m-0 mb-3"
						@click.native="selectAddress(address)"
					>
						<f7-card-content class="p-0">
							<f7-row class="align-items-center" no-gap>
								<f7-col width="20" class="text-align-center">
									<f7-icon f7="placemark" />
								</f7-col>
								<f7-col width="80">
									<div class="py-2 pr-2" style="line-height: 1">
										<h4 class="text-capitalize m-0 mb-1">
											{{ address.annotation }}
										</h4>
										<p class="text-color-gray m-0">
											{{ address.address.line }}
										</p>
									</div>
								</f7-col>
							</f7-row>
						</f7-card-content>
					</f7-card>
					<f7-button large fill raised @click="addNewAddress">
						Add New Address
					</f7-button>
				</div>
			</f7-actions-group>
		</f7-actions>
	</f7-page>
</template>

<script>
import Items from '~/components/items'

export default {
	components: {
		Items
	},
	data () {
		return {
			loading: true,
			showAddresses: false
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'processing',
					value
				})
			}
		},
		isLoggedIn () {
			return this.$store.state.isLoggedIn
		},
		cart: {
			get () {
				return this.$store.state.cart
			},
			set (cart) {
				this.$store.commit('setCart', cart)
			}
		},
		addresses () {
			return this.$store.state.addresses
		},
		deliveryAddress: {
			get () {
				return this.$store.state.deliveryAddress
			},
			set (address) {
				this.$store.commit('setDeliveryAddress', address)
			}
		},
		eligibleDiscounts: {
			get () {
				return this.$store.state.eligibleDiscounts
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'eligibleDiscounts',
					value
				})
			}
		},
		appliedCoupon () {
			const couponArr = this.$store.state.eligibleDiscounts.filter(d => d.code !== null)

			return couponArr.length ? couponArr[0] : null
		}
	},
	watch: {
		cart (cart) {
			if (cart.items.length === 0) {
				this.eligibleDiscounts = []
				this.deliveryAddress = null
				this.calculatePadding()
			}
		},
		addresses (addresses) {
			if (addresses.length === 0 || (this.deliveryAddress && addresses.findIndex((a) => {
				return a.id === this.deliveryAddress.id
			}) === -1)) {
				this.deliveryAddress = null
			}
		}
	},
	beforeMount () {
		this.checkStock()

		if (this.isLoggedIn && this.cart.stall) {
			this.$store.dispatch('getAddresses', {
				lat: this.cart.stall.lat,
				lng: this.cart.stall.lng
			})
		}
	},
	methods: {
		checkStock () {
			this.$store.dispatch('getItems', {
				item_ids: this.cart.items.map(i => i.id),
				is_active: 1
			}).then((response) => {
				response.data.items.forEach((item) => {
					const cartItemIndex = this.cart.items.findIndex(i => i.id === item.id)

					if (cartItemIndex !== -1) {
						this.cart.items[cartItemIndex].inventory = item.inventory

						if (this.cart.items[cartItemIndex].quantity > this.cart.items[cartItemIndex].inventory.stock) {
							this.cart.items[cartItemIndex].quantity = Math.floor(
								this.cart.items[cartItemIndex].inventory.stock * 2
							) / 2
							this.cart.items[cartItemIndex].stockAdjusted = true
						} else {
							delete this.cart.items[cartItemIndex].stockAdjusted
						}
					}
				})

				this.cart = Object.assign({}, this.cart)
				this.$store.dispatch('cartCalculation')
				this.loading = false
				this.calculatePadding()
				this.$nextTick(() => this.$f7.lazy.create(this.$$('#cart')[0]))
			}).catch(() => {
				this.loading = false
			})
		},
		calculatePadding () {
			this.$nextTick(() => {
				const cartFooter = this.$$('#cart .toolbar-button-container')

				this.$$('#cart .page-content').css('padding-bottom', `${
					cartFooter[0] ? cartFooter[0].offsetHeight : 0
				}px`)
			})
		},
		selectAddress (address) {
			this.deliveryAddress = address
			this.showAddresses = false
			setTimeout(() => {
				this.calculatePadding()
				setTimeout(() => {
					this.$$('#cart .page-content').scrollTop(this.$$('#cart .page-content').outerHeight(), 200)
				}, 100)
			}, 500)
		},
		emptyCart () {
			this.$f7.dialog.confirm('You want to empty the cart', 'Are you sure?', () => {
				this.$store.commit('resetCart')
				this.calculatePadding()
			})
		},
		addNewAddress () {
			this.showAddresses = false
			this.$f7router.navigate('/addresses/add?setDeliveryAddress=true')
		},
		initPayments () {
			this.processing = true
			this.$store.dispatch('checkDeliveryAvailability', {
				location_id: this.cart.stall.id,
				lat: this.deliveryAddress.address.lat,
				lng: this.deliveryAddress.address.lng,
				pincode: this.deliveryAddress.address.pincode
			}).then((response) => {
				this.processing = false

				if (response.code === 200) {
					return this.$f7router.navigate('/payments')
				}

				const err = new Error()
				err.response = response

				return Promise.reject(err)
			}).catch((err) => {
				this.processing = false
				this.showErrors(err)
			})
		}
	}
}
</script>

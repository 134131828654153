import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _36c1f26a = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3b3b0e7c = () => interopDefault(import('../pages/addresses/index.vue' /* webpackChunkName: "pages/addresses/index" */))
const _25b30653 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _1396c2ac = () => interopDefault(import('../pages/coupons.vue' /* webpackChunkName: "pages/coupons" */))
const _cdbeb2c2 = () => interopDefault(import('../pages/email-verification.vue' /* webpackChunkName: "pages/email-verification" */))
const _8ef94a9a = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _08548892 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _8ae4a46e = () => interopDefault(import('../pages/intro.vue' /* webpackChunkName: "pages/intro" */))
const _e3ee3c40 = () => interopDefault(import('../pages/items/index.vue' /* webpackChunkName: "pages/items/index" */))
const _4d03eb3b = () => interopDefault(import('../pages/locations.vue' /* webpackChunkName: "pages/locations" */))
const _20217046 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _77514d5b = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _0e5644e3 = () => interopDefault(import('../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _6168934e = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _3066858b = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _00778b64 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _5fe28536 = () => interopDefault(import('../pages/payments/pg.vue' /* webpackChunkName: "pages/payments/pg" */))
const _99d01ca2 = () => interopDefault(import('../pages/settings/edit-profile.vue' /* webpackChunkName: "pages/settings/edit-profile" */))
const _20ccc6ee = () => interopDefault(import('../pages/addresses/_type.vue' /* webpackChunkName: "pages/addresses/_type" */))
const _872bf850 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _5fb44770 = () => interopDefault(import('../pages/items/_id.vue' /* webpackChunkName: "pages/items/_id" */))
const _1fbe42c6 = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _f02d2b1e = () => interopDefault(import('../pages/stalls/_id.vue' /* webpackChunkName: "pages/stalls/_id" */))
const _70575d7d = () => interopDefault(import('../pages/orders/_id/help.vue' /* webpackChunkName: "pages/orders/_id/help" */))
const _7b6026bf = () => interopDefault(import('../pages/orders/_id/track.vue' /* webpackChunkName: "pages/orders/_id/track" */))
const _5679cd2f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _36c1f26a,
    name: "about"
  }, {
    path: "/addresses",
    component: _3b3b0e7c,
    name: "addresses"
  }, {
    path: "/cart",
    component: _25b30653,
    name: "cart"
  }, {
    path: "/coupons",
    component: _1396c2ac,
    name: "coupons"
  }, {
    path: "/email-verification",
    component: _cdbeb2c2,
    name: "email-verification"
  }, {
    path: "/faq",
    component: _8ef94a9a,
    name: "faq"
  }, {
    path: "/home",
    component: _08548892,
    name: "home"
  }, {
    path: "/intro",
    component: _8ae4a46e,
    name: "intro"
  }, {
    path: "/items",
    component: _e3ee3c40,
    name: "items"
  }, {
    path: "/locations",
    component: _4d03eb3b,
    name: "locations"
  }, {
    path: "/login",
    component: _20217046,
    name: "login"
  }, {
    path: "/orders",
    component: _77514d5b,
    name: "orders"
  }, {
    path: "/payments",
    component: _0e5644e3,
    name: "payments"
  }, {
    path: "/settings",
    component: _6168934e,
    name: "settings"
  }, {
    path: "/signup",
    component: _3066858b,
    name: "signup"
  }, {
    path: "/terms",
    component: _00778b64,
    name: "terms"
  }, {
    path: "/payments/pg",
    component: _5fe28536,
    name: "payments-pg"
  }, {
    path: "/settings/edit-profile",
    component: _99d01ca2,
    name: "settings-edit-profile"
  }, {
    path: "/addresses/:type",
    component: _20ccc6ee,
    name: "addresses-type"
  }, {
    path: "/conversations/:id?",
    component: _872bf850,
    name: "conversations-id"
  }, {
    path: "/items/:id",
    component: _5fb44770,
    name: "items-id"
  }, {
    path: "/orders/:id",
    component: _1fbe42c6,
    name: "orders-id"
  }, {
    path: "/stalls/:id?",
    component: _f02d2b1e,
    name: "stalls-id"
  }, {
    path: "/orders/:id/help",
    component: _70575d7d,
    name: "orders-id-help"
  }, {
    path: "/orders/:id/track",
    component: _7b6026bf,
    name: "orders-id-track"
  }, {
    path: "/",
    component: _5679cd2f,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}

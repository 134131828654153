<template>
	<f7-page id="addresses" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar title="Manage Addresses" back-link="Back" />
		<div v-if="loading" class="p-3">
			<f7-card v-for="i in 4" :key="i" class="skeleton-text skeleton-effect-blink m-0 mb-3">
				<f7-card-content class="p-0">
					<f7-row class="align-items-center" no-gap>
						<f7-col width="20" class="text-align-center">
							<f7-icon f7="placemark" />
						</f7-col>
						<f7-col width="80">
							<div class="py-2" style="line-height: 1">
								<h4 class="m-0 mb-1">
									xxxxx
								</h4>
								<p class="m-0">
									xxxxx xxxxx xxxxx xxxxx xxxxx
								</p>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div v-else-if="addresses.length > 0" class="p-3">
			<f7-card v-for="(address, index) in addresses" :key="index" class="m-0 mb-3">
				<f7-card-content class="p-0">
					<f7-row class="align-items-center" no-gap>
						<f7-col width="20" class="text-align-center">
							<f7-icon f7="placemark" />
						</f7-col>
						<f7-col width="80">
							<div class="py-2" style="line-height: 1">
								<f7-row>
									<f7-col width="90">
										<h4 class="text-capitalize m-0">
											{{ address.address.annotation }}
										</h4>
									</f7-col>
									<f7-col width="10">
										<f7-link
											class="float-right"
											icon-f7="ellipsis_vertical"
											icon-size="24"
											@click="setSelectedAddress(address, $event)"
										/>
									</f7-col>
								</f7-row>
								<div class="mr-2">
									<p class="text-color-gray text-capitalize m-0">
										{{ address.address.line }}
									</p>
								</div>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				No addresses found!
			</h2>
		</div>
		<f7-popover ref="popover" class="popover-menu" @popover:closed="selectedAddress = null">
			<f7-list>
				<f7-list-item title="Edit" link="#" no-chevron @click="editAddress">
					<f7-icon slot="media" f7="square_pencil" size="24" />
				</f7-list-item>
				<f7-list-item title="Delete" link="#" no-chevron @click="deleteAddress">
					<f7-icon slot="media" f7="trash" size="24" />
				</f7-list-item>
			</f7-list>
		</f7-popover>
		<f7-button
			large
			fill
			href="/addresses/add"
			style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: unset"
		>
			Add New Address
		</f7-button>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			loading: true,
			selectedAddress: null
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		},
		addresses () {
			return this.$store.state.addresses
		}
	},
	mounted () {
		this.getAddresses()
	},
	methods: {
		getAddresses () {
			this.$store.dispatch('getAddresses').then(() => {
				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		setSelectedAddress (address, $event) {
			this.selectedAddress = address
			this.$refs.popover.open($event.target)
		},
		deleteAddress () {
			const id = this.selectedAddress.id

			this.$refs.popover.close()
			this.$f7.dialog.confirm('You want to delete this address', 'Are you sure?', () => {
				this.processing = true
				this.$store.dispatch('deleteAddress', id).then(() => {
					this.processing = false
					this.$f7router.refreshPage()
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			})
		},
		editAddress () {
			const address = Object.assign({}, this.selectedAddress)

			this.$refs.popover.close()
			this.$f7router.navigate('/addresses/edit', {
				props: {
					address
				}
			})
		}
	}
}
</script>

<style lang="scss">
	#addresses {
		.page-content {
			padding-bottom: 48px;
		}
	}
</style>

<template>
	<f7-page id="conversation">
		<f7-navbar back-link="Back">
			<f7-nav-title style="line-height: 1">
				<p class="m-0">
					Order# {{ orderId }}
				</p>
				<!-- <small style="font-size: 14px">Status: {{ conversation.conversation_status.name }}</small> -->
			</f7-nav-title>
		</f7-navbar>
		<f7-messagebar
			ref="messagebar"
			placeholder="Type here..."
		>
			<f7-link
				slot="inner-end"
				icon-ios="f7:arrow_up_fill"
				icon-aurora="f7:arrow_up_fill"
				icon-md="material:send"
				@click="sendMessage"
			/>
		</f7-messagebar>
		<f7-messages ref="messages" :scroll-messages="scrollMessages" :scroll-messages-on-edge="false">
			<!-- <f7-messages-title><b>Sunday, Feb 9,</b> 12:58</f7-messages-title> -->
			<f7-message
				v-for="(message, index) in messages.data"
				:key="index"
				:type="message.type"
				:text-footer="$moment(message.created_at).calendar()"
				:first="index === 0"
				:last="index === messages.length - 1"
				:tail="index === messages.length - 1"
				:data-id="message.id"
			>
				<!-- eslint-disable-next-line -->
				<span v-if="message.text" slot="text" v-html="message.text" />
			</f7-message>
		</f7-messages>
	</f7-page>
</template>

<script>
export default {
	props: {
		orderId: {
			type: Number,
			required: true
		}
	},
	data () {
		return {
			socket: null,
			messages: {
				data: [],
				pagination: null
			},
			scrollMessages: false,
			timer: null,
			interval: null
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	beforeMount () {
		this.initSocket()
		this.getMessages()
	},
	mounted () {
		this.initPagination()
		this.emitTyping()
	},
	destroyed () {
		clearInterval(this.interval)
		this.socket.close()
	},
	methods: {
		emitTyping () {
			this.$$('textarea').on('keypress', () => {
				this.socket.send(JSON.stringify({ type: 'typing' }))
			})
		},
		getMessages (filters) {
			const firstMsgId = this.$$('.message').eq(0).data('id')

			this.processing = true
			filters = Object.assign({
				conversation_id: this.$f7route.params.id,
				order_by: 'created_at',
				dir: -1
			}, filters)

			return this.$store.dispatch('getMessages', filters).then((response) => {
				if (response.code === 200) {
					const messages = response.data.conversation_messages.map((m) => {
						return {
							id: m.id,
							type: m.created_by.id ? 'received' : 'sent',
							text: m.message
						}
					}).reverse()

					this.messages.pagination = response.data.pagination
					this.messages.data = filters.page ? messages.concat(this.messages.data) : messages

					setTimeout(() => {
						this.$$('.messages-content').scrollTop(
							filters.page
								? (this.$$(`.message[data-id='${firstMsgId}']`).offset().top - 65)
								: this.$$('.messages-content').height()
						)
						this.processing = false
					})
				}
			})
		},
		sendMessage () {
			const message = this.$refs.messagebar.getValue().replace(/\n/g, '<br>').trim()

			if (message.length) {
				this.$refs.messagebar.clear()
				this.messages.data.push({ type: 'sent', text: message })
				this.socket.send(JSON.stringify({
					type: 'message',
					text: message
				}))
				this.enableScrolling()
				this.$store.dispatch('createMessage', {
					conversation_id: this.$f7route.params.id,
					message
				})
			}
		},
		initSocket () {
			this.socket = new WebSocket(
				`${this.getSocketUrl(process.env.WEB_DOMAIN)}/conversations/${this.$f7route.params.id}`
			)

			this.socket.onmessage = (event) => {
				try {
					const data = JSON.parse(event.data)

					if (data.type === 'message') {
						this.messages.data.push({
							type: 'received',
							text: data.text,
							created_at: new Date()
						})

						this.enableScrolling()
					} else if (data.type === 'typing') {
						this.$refs.messages.showTyping()
						clearTimeout(this.timer)
						this.timer = setTimeout(() => {
							if (this.$refs.messages) {
								this.$refs.messages.hideTyping()
							}
						}, 1000)
					}
				} catch (err) {
					console.error(err) // eslint-disable-line
				}
			}

			this.socket.onerror = err => console.error(err) // eslint-disable-line
			this.interval = setInterval(() => {
				if (this.socket.readyState) {
					this.socket.send(JSON.stringify({ type: 'heartbeat' }))
				}
			}, 10000)
		},
		enableScrolling () {
			this.scrollMessages = true
			setTimeout(() => {
				this.scrollMessages = false
			}, 1000)
		},
		initPagination () {
			this.$$('.messages-content').scroll((e) => {
				if (e.target.scrollTop === 0) {
					this.loadMoreResults('messages', 'getMessages')
				}
			})
		}
	}
}
</script>

export default ({ app }) => {
	app.$moment.updateLocale('en', {
		calendar: {
			sameElse: '[next] dddd [at] LT'
		},
		week: {
			dow: 0
		}
	})
}

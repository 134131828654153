<template>
	<f7-page
		id="help"
		infinite
		:infinite-distance="1"
		:infinite-preloader="issues.pagination && issues.pagination.current_page < issues.pagination.last_page"
		@infinite="loadMoreResults('issues', 'getIssues')"
	>
		<f7-navbar title="Help &amp; Support" back-link="Back" />
		<f7-block-title class="mt-3">
			Help with this order
		</f7-block-title>
		<f7-list v-if="loading" class="skeleton-text skeleton-effect-blink" media-list>
			<f7-list-item
				v-for="i in 6"
				:key="i"
				text="xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx"
				link="#"
				no-chevron
			/>
		</f7-list>
		<f7-list v-else media-list class="mb-0">
			<f7-list-item
				v-for="(issue, index) in issues.data"
				:key="index"
				:text="issue.name"
				link="#"
				no-chevron
				@click="createConverstion(issue)"
			/>
		</f7-list>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			loading: true,
			issues: {
				data: [],
				pagination: null
			}
		}
	},
	computed: {
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		}
	},
	beforeMount () {
		this.getIssues()
	},
	methods: {
		getIssues (filters = {}) {
			filters = Object.assign({
				master_type: 'order_issue'
			}, filters)

			return this.$store.dispatch('getMaster', filters).then((response) => {
				if (response.code === 200) {
					this.issues.data = filters.page ? this.issues.data.concat(response.data.masters) : response.data.masters
					this.issues.pagination = response.data.pagination
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		createConverstion (issue) {
			this.processing = true
			this.$store.dispatch('createConverstion', {
				entity_id: this.$f7route.params.id,
				entity: 'orders',
				conversation_status: 'open',
				conversation_type: 'chat',
				subject: issue.name,
				issue_id: issue.id
			}).then((response) => {
				if (response.code === 201) {
					this.$f7router.navigate(`/conversations/${response.data.conversations.id}`, {
						props: {
							orderId: parseInt(this.$f7route.params.id)
						}
					})
				}

				this.processing = false
			}).catch((err) => {
				this.showErrors(err)
				this.processing = false
			})
		}
	}
}
</script>

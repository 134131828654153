import Vue from 'vue'
import utils from '~/assets/js/utils'

export default ({ app }) => {
	app.validateDiscount = utils.validateDiscount

	Vue.mixin({
		methods: {
			...utils
		}
	})
}

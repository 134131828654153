var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"id":"signup"}},[_c('f7-navbar',{attrs:{"title":"Sign up","back-link":"Back","back-link-force":""}}),_vm._v(" "),_c('div',{staticClass:"p-3"},[_c('ValidationObserver',{ref:"validator"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"type":"text","name":"name","placeholder":"Name","error":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|mobile:IN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"type":"number","name":"phone","placeholder":"Mobile Number","error":errors[0]},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"type":"text","name":"email","placeholder":"Email","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|max:16|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"type":"password","name":"password","placeholder":"Password","tooltip":_vm.passwordComplexityMsg(),"error":errors[0],"events":{
						focus: _vm.showTooltip,
						blur: _vm.hideTooltip
					}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_vm._v(" "),_c('f7-button',{staticClass:"display-flex elevation-4 p-3 mb-3",staticStyle:{"height":"54px","background":"white"},on:{"click":function($event){return _vm.connectSocialPlatform('google')}}},[_c('p',{staticClass:"w-100 m-0"},[_c('f7-row',{staticClass:"align-items-center",attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"20"}},[_c('img',{staticClass:"display-flex",attrs:{"src":"/images/google.svg","alt":"Google"}})]),_vm._v(" "),_c('f7-col',{attrs:{"width":"60"}},[_c('span',{staticClass:"float-left font-weight-normal text-capitalize text-color-gray",staticStyle:{"font-size":"18px"}},[_vm._v("\n\t\t\t\t\t\t\t\tConnect"+_vm._s(_vm.google ? 'ed' : '')+" Google\n\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('f7-col',{attrs:{"width":"20"}},[_c('f7-icon',{staticClass:"float-right",attrs:{"f7":_vm.google ? 'checkmark_alt' : 'arrow_right',"color":_vm.google ? 'green' : 'gray'}})],1)],1)],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"position-absolute text-color-red",staticStyle:{"left":"75px","top":"26px","font-weight":"initial","text-transform":"initial"},attrs:{"name":"gp_user_id","tag":"small"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_vm._v("\n\t\t\t\t\t"+_vm._s(errors[0])+"\n\t\t\t\t")]}}])})],1),_vm._v(" "),_c('f7-button',{staticClass:"display-flex elevation-4 p-3 mb-3",staticStyle:{"height":"54px","background":"white"},on:{"click":function($event){return _vm.connectSocialPlatform('facebook')}}},[_c('p',{staticClass:"w-100 m-0"},[_c('f7-row',{staticClass:"align-items-center",attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"20"}},[_c('f7-icon',{staticClass:"float-left",staticStyle:{"color":"#3b5998"},attrs:{"f7":"logo_facebook"}})],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"60"}},[_c('span',{staticClass:"float-left font-weight-normal text-capitalize text-color-gray",staticStyle:{"font-size":"18px"}},[_vm._v("\n\t\t\t\t\t\t\t\tConnect"+_vm._s(_vm.facebook ? 'ed' : '')+" Facebook\n\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('f7-col',{attrs:{"width":"20"}},[_c('f7-icon',{staticClass:"float-right",attrs:{"f7":_vm.facebook ? 'checkmark_alt' : 'arrow_right',"color":_vm.facebook ? 'green' : 'gray'}})],1)],1)],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"position-absolute text-color-red",staticStyle:{"left":"75px","top":"26px","font-weight":"initial","text-transform":"initial"},attrs:{"name":"fb_user_id","tag":"small"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_vm._v("\n\t\t\t\t\t"+_vm._s(errors[0])+"\n\t\t\t\t")]}}])})],1)],1),_vm._v(" "),_c('f7-button',{attrs:{"large":"","raised":"","fill":"","disabled":_vm.processing},on:{"click":_vm.signUp}},[_vm._v("\n\t\t\tCreate Account\n\t\t\t"),_c('f7-icon',{staticClass:"float-right",staticStyle:{"top":"10px"},attrs:{"f7":"arrow_right"}})],1),_vm._v(" "),_c('p',{staticClass:"text-align-center mb-0"},[_vm._v("\n\t\t\tBy creating an account,\n\t\t")]),_vm._v(" "),_c('p',{staticClass:"text-align-center m-0"},[_vm._v("\n\t\t\tI accept the\n\t\t\t"),_c('f7-link',{staticClass:"underline",attrs:{"href":"/terms"}},[_vm._v("\n\t\t\t\tTerms & Conditions\n\t\t\t")])],1)],1),_vm._v(" "),_c('otp-verification',{attrs:{"type":"signup","show":_vm.showOtpVerification,"mobile-number":_vm.mobile,"otp-value":_vm.otp},on:{"closed":function($event){_vm.showOtpVerification = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
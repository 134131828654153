export default (ctx, inject) => {
	if (typeof window.bridge === 'object') {
		inject('bridge', window.bridge)
		delete window.bridge
	} else {
		inject('bridge', {
			getName: () => 'WEB',
			getCurrentLocation: () => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition((position) => {
						window.emitCurrentLocation({
							lat: position.coords.latitude,
							lng: position.coords.longitude
						})
					}, (err) => {
						window.emitCurrentLocation(null)

						if (err.code === 1) {
							window.$nuxt.$f7.dialog
								.alert('Please grant location access to find your location.', 'Access Required')
						}
					}, {
						maximumAge: 10000,
						timeout: 5000,
						enableHighAccuracy: true
					})
				} else {
					window.emitCurrentLocation(null)
					window.$nuxt.$f7.dialog
						.alert('Geolocation is not supported in your browser.', 'Unsupported')
				}
			},
			connectSocialPlatform: (platform) => {
				let auth2

				switch (platform.name) {
				case 'facebook':
					if (window.FB && window.FB.login) {
						const getFBUser = (response) => {
							if (response.status === 'connected') {
								window.FB.api('/me', {
									access_token: response.authResponse.accessToken,
									fields: 'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email'
								}, (fbUser) => {
									window.emitSocialPlatform({
										name: platform.name,
										type: platform.type,
										data: fbUser
									})
								})
							} else {
								window.emitSocialPlatform({
									name: platform.name,
									type: platform.type,
									data: null
								})
							}
						}

						window.FB.getLoginStatus((response) => {
							if (response.status === 'connected') {
								getFBUser(response)
							} else {
								window.FB.login(getFBUser, {
									scope: 'email',
									enable_profile_selector: true
								})
							}
						})
					} else {
						window.emitSocialPlatform({
							name: platform.name,
							type: platform.type,
							data: null
						})
					}

					break
				case 'google':
					if (window.gapi && window.gapi.auth2) {
						auth2 = window.gapi.auth2.getAuthInstance()

						auth2.signIn().then(() => {
							const profile = auth2.currentUser.get().getBasicProfile()

							window.emitSocialPlatform({
								name: platform.name,
								type: platform.type,
								data: {
									id: profile.getId(),
									name: profile.getName(),
									given_name: profile.getGivenName(),
									family_name: profile.getFamilyName(),
									image_url: profile.getImageUrl(),
									email: profile.getEmail()
								}
							})
						}).catch(() => {
							window.emitSocialPlatform({
								name: platform.name,
								type: platform.type,
								data: null
							})
						})
					} else {
						window.emitSocialPlatform({
							name: platform.name,
							type: platform.type,
							data: null
						})
					}

					break
				}
			},
			minimize: () => {},
			initPg: (data) => {
				window.initPg(data)
			},
			closePg: () => {
				window.closePg()
			},
			openDialer: (phoneNumber) => {
				document.location.href = `tel:${phoneNumber}`
			},
			openExternalUrl: (url) => {
				window.open(url)
			}
		})
	}
}

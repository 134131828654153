const mutations = {
	setState (state, data) {
		state[data.key] = data.value
	},
	setUser (state, user) {
		state.user = user

		if (user && typeof this.$sentry.configureScope === 'function') {
			this.$sentry.configureScope((scope) => {
				scope.setUser({
					id: user.id,
					name: user.name
				})
			})
		}
	},
	setIsLoggedIn (state, isLoggedIn) {
		state.isLoggedIn = isLoggedIn
	},
	setProcessing (state, processing) {
		state.processing = processing
	},
	setLocation (state, location) {
		state.location = location

		if (location && typeof this.$sentry.configureScope === 'function') {
			this.$sentry.configureScope((scope) => {
				scope.setExtra('location', location)
			})
		}
	},
	setNearbyStalls (state, stalls) {
		state.nearbyStalls = stalls
	},
	setSelectedStall (state, stall) {
		state.selectedStall = stall

		if (stall && typeof this.$sentry.configureScope === 'function') {
			this.$sentry.configureScope((scope) => {
				scope.setExtra('stall', {
					id: stall.id,
					name: stall.name
				})
			})
		}
	},
	setEligibleDiscounts (state, discounts) {
		state.eligibleDiscounts = discounts
	},
	setCart (state, cart) {
		state.cart = cart
	},
	resetCart (state) {
		state.cart = {
			stall: null,
			items: [],
			weight: 0,
			price: {
				subTotal: 0,
				tax: 0,
				inclusiveTaxTotal: 0,
				exclusiveTaxTotal: 0,
				taxes: [],
				discount: 0,
				discounts: [],
				total: 0
			}
		}
	},
	setSelectedItem (state, item) {
		state.selectedItem = item
	},
	setAddresses (state, addresses) {
		state.addresses = addresses
	},
	setDeliveryAddress (state, address) {
		state.deliveryAddress = address
	},
	setPaymentMethod (state, paymentMethod) {
		state.paymentMethod = paymentMethod
	},
	setNavigationProps (state, props) {
		state.navigationProps = Object.assign({}, state.navigationProps, props)
	},
	removeNavigationProp (state, key) {
		const props = { ...state.navigationProps }

		delete props[key]
		state.navigationProps = props
	},
	setNoStallFeedback (state, feedback) {
		state.noStallFeedback = feedback
	}
}

export default mutations

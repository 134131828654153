<template>
	<f7-actions
		id="otp-verification"
		ref="otpVerifiaction"
		:class="type"
		:opened="show"
		@actions:opened="initOtp"
		@actions:closed="close"
	>
		<f7-actions-group>
			<f7-actions-label>
				<span v-if="type === 'forgot-password'">
					{{ otpVerified ? 'Reset' : 'Forgot' }} Password
				</span>
				<span v-else>
					OTP Verification
				</span>
				<f7-link
					icon-f7="xmark"
					icon-size="20px"
					class="position-absolute"
					style="top: 12px; right: 12px"
					@click="$refs.otpVerifiaction.close()"
				/>
			</f7-actions-label>
			<div v-if="!otpVerified" class="bg-color-white text-align-center p-3">
				<p class="m-0">
					Enter the OTP sent to <strong>{{ mobileNumber }}</strong>
					<span v-if="type === 'forgot-password'">to reset the password</span>
				</p>
				<p class="text-color-blue">
					{{ $moment().startOf('day').seconds(seconds).format('mm:ss') }}
				</p>
				<p v-if="otpValue" class="m-0">
					Your OTP is {{ otpValue }}
				</p>
				<f7-list no-hairlines>
					<f7-row>
						<f7-col>
							<f7-list-input type="number" maxlength="1" data-index="0" />
						</f7-col>
						<f7-col>
							<f7-list-input type="number" maxlength="1" data-index="1" />
						</f7-col>
						<f7-col>
							<f7-list-input type="number" maxlength="1" data-index="2" />
						</f7-col>
						<f7-col>
							<f7-list-input type="number" maxlength="1" data-index="3" />
						</f7-col>
						<f7-col>
							<f7-list-input type="number" maxlength="1" data-index="4" />
						</f7-col>
						<f7-col>
							<f7-list-input type="number" maxlength="1" data-index="5" />
						</f7-col>
					</f7-row>
					<f7-row>
						<f7-col>
							<p class="text-color-red mb-0" style="height: 21px">
								{{ errorMsg }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list>
				<p class="text-color-gray">
					Didn't receive OTP?
					<f7-link @click="resendOtp">
						<strong>RESEND OTP</strong>
					</f7-link>
				</p>
			</div>
			<div v-else class="bg-color-white text-align-center p-3">
				<ValidationObserver ref="validator">
					<ValidationProvider
						v-slot="{ errors }"
						name="password"
						rules="required|min:6|max:18|password:confirmation"
					>
						<text-input
							v-model="password"
							type="password"
							name="password"
							placeholder="New Password"
							:tooltip="passwordComplexityMsg()"
							:error="errors[0]"
							:events="{
								focus: showTooltip,
								blur: hideTooltip
							}"
						/>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{ errors }"
						vid="confirmation"
						name="confirm password"
						rules="required|min:6|max:18"
					>
						<text-input
							v-model="passwordConfirmation"
							type="password"
							name="confirm-password"
							placeholder="Confirm New Password"
							:error="errors[0]"
						/>
					</ValidationProvider>
				</ValidationObserver>
				<div class="display-flex justify-content-center">
					<f7-button
						class="w-100"
						large
						fill
						raised
						:disabled="processing"
						@click="resetPassword"
					>
						Reset
						<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
					</f7-button>
				</div>
			</div>
		</f7-actions-group>
	</f7-actions>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextInput from '~/components/text-input'

const initialState = () => ({
	timer: null,
	seconds: 300,
	otp: '',
	errorMsg: '',
	otpVerified: false,
	password: '',
	passwordConfirmation: ''
})

export default {
	components: {
		TextInput,
		ValidationObserver,
		ValidationProvider
	},
	props: {
		type: {
			type: String,
			required: true
		},
		show: {
			type: Boolean,
			required: true
		},
		mobileNumber: {
			type: String,
			required: true
		},
		otpValue: {
			type: String,
			default: () => ''
		}
	},
	data () {
		return initialState()
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	watch: {
		otp (otp) {
			if (otp.length) {
				this.errorMsg = ''
			}
		}
	},
	methods: {
		initTimer () {
			this.timer = setInterval(() => {
				this.seconds--

				if (this.seconds <= 0) {
					clearInterval(this.timer)
					this.close()
				}
			}, 1000)
		},
		generateOtp ($event) {
			const index = parseInt($event.target.closest('li').getAttribute('data-index'))

			if ($event.target.value) {
				this.otp += $event.target.value

				if (this.otp.length < 6) {
					this.$$(`#otp-verification.${this.type} input`).eq(index + 1).focus()
				} else {
					this.$$(`#otp-verification.${this.type} input.input-focused`).eq(0).blur()
					this.processing = true
					this.$store.dispatch('verifyOtp', { otp: this.otp })
						.then((response) => {
							if (response.code === 200) {
								if (this.type === 'signup') {
									this.$refs.otpVerifiaction.close()
									this.$f7router.navigate('/home', {
										clearPreviousHistory: true
									})
								} else if (this.type === 'forgot-password') {
									this.otpVerified = true
								} else {
									this.close()
									this.$f7.toast.create({
										text: 'Mobile number changed successfully',
										closeTimeout: 3000
									}).open()
								}
							}

							this.processing = false
						})
						.catch((err) => {
							if (err.response && err.response.data) {
								this.errorMsg = err.response.data.message
							}

							this.$$(`#otp-verification.${this.type} input`).forEach((i) => { i.value = '' })
							this.otp = ''
							this.focusInput(`#otp-verification.${this.type} input`)
							this.processing = false
						})
				}
			} else if ($event.keyCode === 8) {
				this.otp = this.otp.slice(0, -1)

				if (index > 0) {
					this.$$(`#otp-verification.${this.type} input`).eq(index - 1).focus()
				}
			}
		},
		close () {
			clearInterval(this.timer)
			Object.assign(this.$data, initialState())
			this.$$(`#otp-verification.${this.type} input`).forEach((i) => { i.value = '' })
			setTimeout(() => this.$emit('closed'), 200)
		},
		initOtp () {
			this.initTimer()
			this.focusInput(`#otp-verification.${this.type} input`)
			this.$$(`#otp-verification.${this.type} input[type='number']`).forEach((el) => {
				el.addEventListener('keyup', this.generateOtp)
				el.addEventListener('keypress', ($event) => {
					if ($event.target.value.length >= 1) {
						$event.preventDefault()
						return false
					}
				})
			})
		},
		async resetPassword () {
			if (await this.$refs.validator.validate()) {
				this.processing = true
				this.$store.dispatch('resetPassword', {
					password: this.password,
					password_confirmation: this.passwordConfirmation
				}).then((response) => {
					if (response.code === 200) {
						this.$refs.otpVerifiaction.close()
						this.$f7.toast.create({
							text: 'Login with your new password to continue',
							closeTimeout: 3000
						}).open()
					}

					this.processing = false
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			}
		},
		resendOtp () {
			this.processing = true
			this.$store.dispatch('sendOtp', { phone: this.mobileNumber }).then((response) => {
				if (response.code === 200) {
					clearInterval(this.timer)
					this.seconds = 300
					this.initTimer()
					this.focusInput(`#otp-verification.${this.type} input`)
					this.$emit('update-otp', response.data.otp || null)
				}

				this.processing = false
			}).catch((err) => {
				this.showErrors(err)
				this.processing = false
			})
		}
	}
}
</script>

<style lang="scss">
	#otp-verification {
		li {
			list-style: none;
		}

		.item-input {
			padding-left: 0;

			.item-inner {
				padding: 0
			}

			.item-input-wrap {
				input {
					text-align: center;
				}

				&::after {
					background-color: gray;
				}
			}
		}
	}
</style>

<template>
	<f7-page id="login">
		<img src="/images/fishes.jpeg" alt="Fishes" width="100%">
		<div class="content p-3">
			<h1 class="m-0">
				Welcome back!
			</h1>
			<p class="text-color-gray mt-0">
				Please login to your account.
			</p>
			<ValidationObserver ref="validator">
				<ValidationProvider
					ref="mobileValidator"
					v-slot="{ errors }"
					name="mobile number"
					rules="required|mobile:IN"
				>
					<text-input
						v-model="mobile"
						type="number"
						name="mobile"
						placeholder="Mobile Number"
						:error="errors[0]"
					/>
				</ValidationProvider>
				<ValidationProvider
					v-if="showPassword"
					v-slot="{ errors }"
					name="password"
					rules="required|min:6|max:18"
				>
					<text-input
						v-model="password"
						type="password"
						name="password"
						placeholder="Password"
						:error="errors[0]"
					/>
				</ValidationProvider>
			</ValidationObserver>
			<f7-button
				v-if="!showPassword"
				large
				raised
				fill
				class="mb-4"
				:disabled="processing"
				@click="verifyMobile"
			>
				Next
				<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
			</f7-button>
			<f7-block class="text-align-right px-0 mt-0 mb-3">
				<f7-link v-if="showPassword" class="underline" @click="forgotPassword">
					Forgot Password?
				</f7-link>
			</f7-block>
			<f7-button
				v-if="showPassword"
				large
				raised
				fill
				class="mb-4"
				:disabled="processing"
				@click="validate"
			>
				Login
				<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
			</f7-button>
			<p class="line-with-words mb-4" :class="{ 'mt-4': !showPassword }">
				<span>or</span>
			</p>
			<f7-block class="text-align-center px-0 mt-0 mb-3">
				<f7-link class="mr-4" @click="socialLogin('google')">
					<img src="/images/google.svg" alt="Google">
				</f7-link>
				<f7-link
					icon-size="32px"
					icon-f7="logo_facebook"
					style="color: #4267b2"
					@click="socialLogin('facebook')"
				/>
			</f7-block>
			<p class="text-align-center m-0">
				Don't have an account?
				<f7-link href="/signup" class="underline">
					Register now
				</f7-link>
			</p>
		</div>
		<otp-verification
			:type="otpVerifiactionType"
			:show="otpVerifiactionType !== ''"
			:mobile-number="mobile"
			:otp-value="otp"
			@closed="otpVerifiactionType = ''"
		/>
	</f7-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextInput from '~/components/text-input'
import OtpVerification from '~/components/otp-verification'

export default {
	components: {
		TextInput,
		OtpVerification,
		ValidationObserver,
		ValidationProvider
	},
	data () {
		return {
			mobile: '',
			password: '',
			showPassword: false,
			otpVerifiactionType: '',
			loggingIn: false,
			otp: null
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	mounted () {
		this.focusInput('input[type="number"]')

		if (this.$bridge.getName() === 'WEB') {
			this.initGoogleSdk()
			this.initFacebookSdk()
		}

		window.emitSocialPlatform = this.emitSocialPlatform
	},
	methods: {
		async verifyMobile () {
			if ((await this.$refs.mobileValidator.validate()).valid) {
				this.processing = true
				this.$store.dispatch('verifyMobile', {
					phone: this.mobile
				}).then((response) => {
					if (response.code === 200) {
						this.showPassword = true
						this.focusInput('#login input[type="password"]')
					}

					this.processing = false
				}).catch((err) => {
					if (err.response && err.response.status === 404) {
						this.$f7router.navigate(`/signup?mobile=${this.mobile}`)
						setTimeout(() => {
							this.$f7.toast.create({
								text: 'Mobile number not registered! Please sign up.',
								closeTimeout: 3000
							}).open()
						}, 300)
					} else {
						this.showErrors(err)
					}

					this.processing = false
				})
			}
		},
		login (data) {
			const accountLockingTime = this.getLocalStorage('accountLockingTime')

			if (accountLockingTime) {
				const seconds = this.$moment(new Date())
					.diff(this.$moment(new Date(accountLockingTime)), 'seconds')

				if (seconds < 600) {
					let remaining = Math.round(10 - (seconds / 60))
					remaining = remaining === 0 ? 1 : remaining

					return this.$f7.toast.create({
						text: `Login attempts exceeded! Please try again after ${remaining} min${
							remaining > 1 ? 's' : ''
						}.`,
						closeTimeout: 3000
					}).open()
				}

				this.removeLocalStorage('accountLockingTime')
			}

			this.processing = true

			this.$store.dispatch('login', data).then((response) => {
				if (response.code === 200) {
					this.removeLocalStorage('accountLockingTime')

					if (response.data.info.is_phone_verified) {
						this.$f7router.navigate('/home', {
							clearPreviousHistory: true
						})
					} else {
						this.$store.dispatch('sendOtp', { phone: this.mobile })
						this.otp = response.data.otp || null
						this.otpVerifiactionType = 'signup'
					}
				}

				this.processing = false
			}).catch((err) => {
				this.processing = false

				if ((data.gp_user_id || data.fb_user_id) && err.response && err.response.status === 401) {
					this.$f7router.navigate('/signup', { props: data })

					return setTimeout(() => {
						this.$f7.toast.create({
							text: 'Email not registered! Please sign up.',
							closeTimeout: 3000
						}).open()
					}, 300)
				} else if (err.response && err.response.status === 403) {
					this.setLocalStorage('accountLockingTime', new Date())
				}

				this.showErrors(err)
			})
		},
		async validate () {
			if (await this.$refs.validator.validate()) {
				this.login({
					phone: this.mobile,
					password: this.password
				})
			}
		},
		async forgotPassword () {
			if ((await this.$refs.mobileValidator.validate()).valid) {
				this.processing = true
				this.$store.dispatch('forgotPassword', {
					phone: this.mobile
				}).then((response) => {
					if (response.code === 200) {
						this.$store.dispatch('sendOtp', { phone: this.mobile })
						this.otp = response.data.otp || null
						this.otpVerifiactionType = 'forgot-password'
					}

					this.processing = false
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			}
		},
		socialLogin (platform) {
			const data = {
				name: platform,
				type: 'login'
			}

			this.processing = true
			this.$bridge.connectSocialPlatform(
				this.$bridge.getName() === 'ANDROID' ? JSON.stringify(data) : data
			)
		},
		emitSocialPlatform (platform) {
			this.processing = false

			if (typeof platform === 'string') {
				platform = JSON.parse(platform)
			}

			if (platform.type === 'login') {
				if (platform.data) {
					const key = platform.name === 'google' ? 'gp' : 'fb'

					this.login({
						[`${key}_user_id`]: platform.data.id,
						[`${key}_data`]: platform.data
					})
				} else {
					this.$f7.toast.create({
						text: `Sorry! we couldn't connect to your ${platform.name} account.`,
						closeTimeout: 3000
					}).open()
				}
			}
		}
	}
}
</script>

<style lang="scss">
	#login .content {
		position: absolute;
		left: 0;
		right: 0;
		top: 180px;
		background-color: #F5F5F5;
		border-radius: 20px;
	}
</style>

// Import Vue
import Vue from 'vue' // eslint-disable-line

// Import Framework7 Bundle
import Framework7 from 'framework7/framework7-lite.esm.bundle.js'

// Import Framework7-Vue with all components
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'

// Init plugin and register all components
Framework7.use(Framework7Vue)

<template>
	<f7-page id="settings">
		<f7-navbar title="Settings" back-link="Back" />
		<f7-block-title class="mt-3">
			Account
		</f7-block-title>
		<f7-list class="mb-0">
			<f7-list-item title="Edit Profile" link="/settings/edit-profile" />
			<f7-list-item
				link="#"
				header="Mobile Number"
				after="Edit"
				@click="showChangeMobile = true"
			>
				<div slot="title">
					{{ user.phone }}
					<f7-icon
						v-if="user.is_phone_verified"
						f7="checkmark_alt_circle_fill"
						color="green"
						size="18px"
					/>
					<f7-icon v-else f7="exclamationmark_circle_fill" color="red" size="18px" />
				</div>
			</f7-list-item>
			<f7-list-item
				link="#"
				header="Email Address"
				after="Edit"
				@click="showChangeEmail = true"
			>
				<div slot="title">
					{{ user.email }}
					<f7-icon
						v-if="user.is_email_verified"
						f7="checkmark_alt_circle_fill"
						color="green"
						size="18px"
					/>
					<f7-icon v-else f7="exclamationmark_circle_fill" color="red" size="18px" />
				</div>
			</f7-list-item>
			<f7-list-item title="Change Password" link="#" @click="showChangePassword = true" />
		</f7-list>
		<f7-block-title class="mt-3">
			Help
		</f7-block-title>
		<f7-list class="mb-0">
			<f7-list-item title="FAQ" link="/faq" />
		</f7-list>
		<f7-actions
			id="change-mobile"
			:opened="showChangeMobile"
			@actions:open="initMobileActionSheet"
			@actions:opened="focusInput('#change-mobile input')"
			@actions:closed="showChangeMobile = false"
		>
			<f7-actions-group>
				<f7-actions-label>
					Change Mobile Number
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						class="position-absolute"
						style="top: 12px; right: 12px"
						@click="showChangeMobile = false"
					/>
				</f7-actions-label>
				<div class="bg-color-white text-align-center p-3">
					<ValidationObserver ref="changeMobileValidator">
						<ValidationProvider
							v-slot="{ errors }"
							name="phone"
							rules="required|mobile:IN"
						>
							<text-input
								v-model="mobile"
								type="number"
								name="phone"
								placeholder="Mobile Number"
								:error="errors[0]"
							/>
						</ValidationProvider>
					</ValidationObserver>
					<div class="display-flex justify-content-center">
						<f7-button
							class="text-color-white w-100"
							style="font-weight: 900; font-size: medium"
							large
							raised
							fill
							:disabled="processing"
							@click="changeMobileNumber"
						>
							Next
							<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
						</f7-button>
					</div>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			id="change-email"
			:opened="showChangeEmail"
			@actions:open="initEmailActionSheet"
			@actions:opened="focusInput('#change-email input')"
			@actions:closed="showChangeEmail = false"
		>
			<f7-actions-group>
				<f7-actions-label>
					Change Email
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						class="position-absolute"
						style="top: 12px; right: 12px"
						@click="showChangeEmail = false"
					/>
				</f7-actions-label>
				<div class="bg-color-white text-align-center p-3">
					<ValidationObserver ref="changeEmailValidator">
						<ValidationProvider
							v-slot="{ errors }"
							name="email"
							rules="required|email"
						>
							<text-input
								v-model="email"
								type="text"
								name="email"
								placeholder="Email Address"
								:error="errors[0]"
							/>
						</ValidationProvider>
					</ValidationObserver>
					<div class="display-flex justify-content-center">
						<f7-button
							class="text-color-white w-100"
							style="font-weight: 900; font-size: medium"
							large
							raised
							fill
							:disabled="processing"
							@click="changeEmail"
						>
							Save
						</f7-button>
					</div>
				</div>
			</f7-actions-group>
		</f7-actions>
		<f7-actions
			id="change-password"
			:opened="showChangePassword"
			@actions:open="initPasswordActionSheet"
			@actions:opened="focusInput('#change-password input')"
			@actions:closed="showChangePassword = false"
		>
			<f7-actions-group>
				<f7-actions-label>
					Change Password
					<f7-link
						icon-f7="xmark"
						icon-size="20px"
						class="position-absolute"
						style="top: 12px; right: 12px"
						@click="showChangePassword = false"
					/>
				</f7-actions-label>
				<div class="bg-color-white text-align-center p-3">
					<ValidationObserver ref="validator">
						<ValidationProvider
							v-slot="{ errors }"
							name="current_password"
							rules="required|min:6|max:18"
						>
							<text-input
								v-model="currentPassword"
								type="password"
								name="current_password"
								placeholder="Current Password"
								:error="errors[0]"
							/>
						</ValidationProvider>
						<ValidationProvider
							v-slot="{ errors }"
							vid="password"
							name="password"
							rules="required|min:8|max:16|password"
						>
							<text-input
								v-model="newPassword"
								type="password"
								name="password"
								placeholder="New Password"
								:tooltip="passwordComplexityMsg()"
								:error="errors[0]"
								:events="{
									focus: showTooltip,
									blur: hideTooltip
								}"
							/>
						</ValidationProvider>
						<ValidationProvider
							v-slot="{ errors }"
							name="password_confirmation"
							rules="required|equals:password"
						>
							<text-input
								v-model="newPasswordConfirmation"
								type="password"
								name="password_confirmation"
								placeholder="Confirm New Password"
								:error="errors[0]"
							/>
						</ValidationProvider>
					</ValidationObserver>
					<div class="display-flex justify-content-center">
						<f7-button
							class="w-100"
							large
							raised
							fill
							:disabled="processing"
							@click="changePassword"
						>
							Change
							<f7-icon class="float-right" f7="arrow_right" style="top: 10px" />
						</f7-button>
					</div>
				</div>
			</f7-actions-group>
		</f7-actions>
		<otp-verification
			type="change-password"
			:show="showOtpVerification"
			:mobile-number="mobile"
			:otp-value="otp"
			@update-otp="otp = $event"
			@closed="showOtpVerification = false"
		/>
	</f7-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextInput from '~/components/text-input'
import OtpVerification from '~/components/otp-verification'

export default {
	components: {
		TextInput,
		OtpVerification,
		ValidationObserver,
		ValidationProvider
	},
	data () {
		return {
			showChangeMobile: false,
			showChangeEmail: false,
			showOtpVerification: false,
			mobile: '',
			email: '',
			showChangePassword: false,
			currentPassword: '',
			newPassword: '',
			newPasswordConfirmation: '',
			otp: null
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	methods: {
		async changePassword () {
			if (await this.$refs.validator.validate()) {
				this.processing = true
				this.$store.dispatch('changePassword', {
					current_password: this.currentPassword,
					password: this.newPassword,
					password_confirmation: this.newPasswordConfirmation
				}).then((response) => {
					if (response.code === 200) {
						this.showChangePassword = false
						this.$f7.toast.create({
							text: 'Password changed successfully!',
							closeTimeout: 3000
						}).open()
					}

					this.processing = false
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			}
		},
		async changeMobileNumber () {
			if (await this.$refs.changeMobileValidator.validate()) {
				this.processing = true
				this.$store.dispatch('changeMobile', {
					phone: this.mobile
				}).then((response) => {
					if (response.code === 200) {
						this.$store.dispatch('sendOtp', { phone: this.mobile })
						this.otp = response.data.otp || null
						this.showChangeMobile = false
						this.showOtpVerification = true
					}

					this.processing = false
				}).catch((err) => {
					this.$refs.validator = this.$refs.changeMobileValidator
					this.showErrors(err)
					this.processing = false
				})
			}
		},
		async changeEmail () {
			if (await this.$refs.changeEmailValidator.validate()) {
				this.processing = true
				this.$store.dispatch('changeEmail', {
					email: this.email
				}).then((response) => {
					if (response.code === 200) {
						this.$store.dispatch('sendEmail', {
							to: [this.email],
							subject: 'Confirm Your Email Address'
						})
						this.showChangeEmail = false
						this.$f7.dialog.alert(`A confirmation email has been sent to ${this.email}. Click on the confirmation link in the email to verify your email address.`, 'Confirm your email address')
					}

					this.processing = false
				}).catch((err) => {
					this.$refs.validator = this.$refs.changeEmailValidator
					this.showErrors(err)
					this.processing = false
				})
			}
		},
		initMobileActionSheet () {
			this.mobile = this.user.phone
			window.requestAnimationFrame(this.$refs.changeMobileValidator.reset)
		},
		initEmailActionSheet () {
			this.email = this.user.email
			window.requestAnimationFrame(this.$refs.changeEmailValidator.reset)
		},
		initPasswordActionSheet () {
			this.currentPassword = ''
			this.newPassword = ''
			this.newPasswordConfirmation = ''
			window.requestAnimationFrame(this.$refs.validator.reset)
		}
	}
}
</script>

<template>
	<f7-page id="item-details">
		<f7-photo-browser
			ref="photos"
			type="popup"
			theme="dark"
			:photos="item.images"
		/>
		<div class="position-fixed" style="top: 0; left: 0; right: 0; background-color: #F5F5F5; z-index: 2">
			<div
				:data-background="item.images[0] || fishPlaceholder"
				class="position-relative lazy lazy-fade-in"
				style="height: 200px; background: #ccc; background-size: cover; background-repeat: no-repeat; background-position: center"
				@click="$refs.photos.open(0)"
			>
				<f7-link
					href="#"
					style="position: absolute; right: 15px; top: 15px; background-color: white; border-radius: 50%"
					icon-f7="xmark_circle_fill"
					back
					@click.stop="$store.commit('removeNavigationProp', 'item')"
				/>
				<h3 class="position-absolute font-weight-bold text-color-white m-0 px-3" style="background-color: rgba(0,0,0,.5); bottom: 15px">
					{{ item.name }}
				</h3>
			</div>
			<f7-toolbar tabbar labels position="top" style="top: 40%">
				<f7-link tab-link="#items-tab" text="BUY" icon-f7="cart_badge_plus" icon-size="20" tab-link-active />
				<f7-link tab-link="#item-info-tab" text="INFO" icon-f7="info_circle" icon-size="20" />
				<f7-link tab-link="#item-stalls-tab" text="STALLS" icon-material="store" icon-size="20" />
			</f7-toolbar>
		</div>
		<f7-tabs swipeable>
			<f7-tab id="items-tab" class="page-content" tab-active>
				<items :items="item.items && item.items.length ? item.items : [item]" />
			</f7-tab>
			<f7-tab id="item-info-tab" class="page-content">
				<f7-card class="m-3">
					<f7-card-content class="p-3">
						<p class="text-color-gray m-0" style="font-size: large">
							{{ item.description }}
						</p>
					</f7-card-content>
				</f7-card>
			</f7-tab>
			<f7-tab id="item-stalls-tab" class="page-content" @tab:show="getItemStalls">
				<f7-card
					v-for="(itemStall, index) in itemStalls"
					:key="index"
					class="m-3"
					style="line-height: 1"
					@click.native="showStallDetails(itemStall)"
				>
					<f7-card-content class="p-2">
						<f7-row class="align-items-center" no-gap>
							<f7-col width="20" class="text-align-center">
								<f7-icon f7="placemark" class="mb-1" />
								<small class="display-block font-weight-bold">
									{{ calculateDistance(location.lat, location.lng, itemStall.location.lat, itemStall.location.lng) | currency({
										symbol: ''
									}) }} km
								</small>
							</f7-col>
							<f7-col width="80">
								<div class="p-2">
									<f7-row class="align-items-center mb-2" no-gap>
										<f7-col width="70">
											<h4 class="text-capitalize m-0">
												{{ itemStall.location.name }}
											</h4>
										</f7-col>
										<f7-col width="30">
											<f7-badge :color="itemStall.in_stock ? 'green' : 'red'" class="float-right">
												{{ itemStall.in_stock ? 'AVAILABLE' : 'SOLD OUT' }}
											</f7-badge>
										</f7-col>
									</f7-row>
									<p class="text-color-gray text-capitalize m-0">
										{{ itemStall.location.address.line }}
									</p>
								</div>
							</f7-col>
						</f7-row>
					</f7-card-content>
				</f7-card>
			</f7-tab>
		</f7-tabs>
		<cart-toolbar />
	</f7-page>
</template>

<script>
import fishPlaceholder from '~/assets/images/fish-placeholder.png'
import Items from '~/components/items'
import CartToolbar from '~/components/cart-toolbar'

export default {
	components: {
		Items,
		CartToolbar
	},
	data () {
		return {
			item: null,
			fishPlaceholder,
			itemStalls: []
		}
	},
	computed: {
		location () {
			return this.$store.state.location
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		},
		itemProp () {
			return this.$store.getters.navigationProp('item')
		}
	},
	beforeMount () {
		this.item = this.itemProp
	},
	methods: {
		getItemStalls () {
			this.processing = true
			this.$store.dispatch('getItemStalls', this.item.id).then((response) => {
				if (response.code === 200) {
					this.itemStalls = response.data.item_inventories
				}

				this.processing = false
			}).catch((err) => {
				this.showErrors(err)
				this.processing = false
			})
		},
		showStallDetails (stall) {
			this.$store.commit('setNavigationProps', { stall })
			this.$f7router.navigate(`/stalls/${stall.location.id}`, {
				props: { stall }
			})
		}
	}
}
</script>

<style lang="scss">
	#item-details {
		.page-content:not(.tab) {
			padding-top: 256px;
		}
	}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"id":"home","ptr":"","ptr-distance":60,"infinite":"","infinite-distance":1,"infinite-preloader":_vm.items.pagination && _vm.items.pagination.current_page < _vm.items.pagination.last_page},on:{"ptr:refresh":_vm.refresh,"infinite":function($event){return _vm.loadMoreResults('items', 'getItems')}}},[_c('f7-navbar',[_c('f7-nav-left',[_c('f7-link',{attrs:{"panel-open":"left","icon-f7":"bars"}})],1),_vm._v(" "),_c('f7-nav-title',[_c('p',{staticClass:"m-0",on:{"click":function($event){return _vm.$f7router.navigate('/locations')}}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.location ? (_vm.location.area || _vm.location.city || (
					_vm.location.line ? _vm.location.line.split(', ')[0] : 'Unnamed Location'
				)) : 'Set Location')+"\n\t\t\t")])]),_vm._v(" "),_c('f7-nav-right',[_c('f7-link',{attrs:{"href":"/items?type=search%20items","icon-f7":"search"}})],1)],1),_vm._v(" "),(_vm.loading || _vm.popularItems.length > 0)?_c('h4',{staticClass:"px-3"},[_vm._v("\n\t\tPopular Items\n\t\t"),_c('f7-link',{staticClass:"float-right",attrs:{"href":"/items?type=popular%20items","text":"View All"}})],1):_vm._e(),_vm._v(" "),_c('f7-swiper',{staticClass:"px-3",attrs:{"params":{
			slidesPerView: 'auto',
			spaceBetween: 30,
			preloadImages: false,
			lazy: true
		}}},_vm._l((_vm.loading ? [1, 2, 3] : _vm.popularItems),function(item,index){return _c('f7-swiper-slide',{key:("popular-item-" + index),staticClass:"w-75 pb-1"},[(_vm.loading)?_c('f7-card',{staticClass:"skeleton-text skeleton-effect-blink elevation-2 m-0"},[_c('f7-skeleton-block',{staticStyle:{"height":"150px"}}),_vm._v(" "),_c('f7-card-content',{staticClass:"p-2"},[_c('h4',{staticClass:"m-0"},[_vm._v("\n\t\t\t\t\t\tItem Name\n\t\t\t\t\t")]),_vm._v(" "),_c('h4',{staticClass:"m-0"},[_vm._v("\n\t\t\t\t\t\tPrice Details\n\t\t\t\t\t")])])],1):_c('f7-card',{staticClass:"elevation-2 m-0",nativeOn:{"click":function($event){return _vm.showItemDetails(item)}}},[(item.discount && item.discount.id)?_c('ribbon',{attrs:{"text":((item.discount.title) + " on coupon " + (item.discount.code))}}):_vm._e(),_vm._v(" "),_c('f7-card-header',{staticClass:"lazy lazy-fade-in no-border",staticStyle:{"height":"150px","background":"#ccc","background-size":"cover","background-repeat":"no-repeat","background-position":"center"},attrs:{"data-background":item.images[0] || _vm.fishPlaceholder,"valign":"bottom"}}),_vm._v(" "),_c('f7-card-content',{staticClass:"p-2"},[_c('h4',{staticClass:"m-0"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('h4',{staticClass:"text-color-blue m-0"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm._f("currency")(item.inventory.price))+"\n\t\t\t\t\t\t"),(item.unit_measure_type.short_name === 'weight')?_c('span',[_vm._v(" / KG")]):_vm._e()])])],1)],1)}),1),_vm._v(" "),(_vm.loading || _vm.nearbyStalls.length > 0)?_c('h4',{staticClass:"px-3"},[(_vm.loading)?_c('span',[_vm._v("Nearby Stalls")]):(_vm.nearbyStalls.length)?_c('span',[_vm._v("\n\t\t\tFound "+_vm._s(_vm.nearbyStalls.length)+" Nearby Stall"+_vm._s(_vm.nearbyStalls.length === 1 ? '' : 's')+"\n\t\t")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('f7-swiper',{staticClass:"px-3",attrs:{"id":"stalls-swiper","params":{
			slidesPerView: 'auto',
			spaceBetween: 10
		}}},_vm._l((_vm.loading ? [1, 2, 3] : _vm.nearbyStalls),function(stall,index){return _c('f7-swiper-slide',{key:("stall-" + index)},[(_vm.loading)?_c('f7-chip',{staticClass:"w-100 skeleton-text skeleton-effect-blink text-color-black bg-color-white elevation-2",attrs:{"text":"Stall Location Name"}},[_c('f7-skeleton-block',{staticStyle:{"height":"32px","width":"32px","border-radius":"50%"},attrs:{"slot":"media"},slot:"media"})],1):_c('f7-chip',{staticClass:"w-100 text-color-black bg-color-white elevation-2",class:{
					active: _vm.selectedStall && _vm.selectedStall.id === stall.id
				},attrs:{"text":stall.name},on:{"click":function($event){return _vm.setSelectedStall(stall)}}},[_c('f7-icon',{attrs:{"slot":"media","material":"store"},slot:"media"})],1)],1)}),1),_vm._v(" "),(!_vm.loading && _vm.selectedStall && !_vm.isSelectedStallActive)?_c('small',{staticClass:"display-block text-color-red px-3 my-3"},[_vm._v("\n\t\tOnline orders have closed at "+_vm._s(_vm.selectedStall.name)+". "+_vm._s(_vm.nextStallOpeningTime(_vm.selectedStall))+"\n\t")]):(!_vm.loading && _vm.selectedStall && !_vm.selectedStall.delivery_stock)?_c('small',{staticClass:"display-block text-color-red px-3 my-3"},[_vm._v("\n\t\tDue to high demand in your area, delivery partners are busy! Please try after some time.\n\t")]):_vm._e(),_vm._v(" "),_c('items',{attrs:{"loading":_vm.loading,"items":_vm.items.data}}),_vm._v(" "),_c('variations'),_vm._v(" "),_c('cart-toolbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const routes = {}
const requireComponent = require.context('@/pages', true, /\w+\.vue$/)

requireComponent.keys().forEach((fileName) => {
	routes[
		fileName.substr(2, fileName.length - 6).replace(/\/_/g, '-').replace(/\/index/g, '').replace(/\//g, '-')
	] = requireComponent(fileName).default
})

export default (ctx, inject) => {
	inject('routes', ctx.app.router.options.routes.map((route) => {
		return {
			...route,
			component: routes[route.name]
		}
	}))
}

/* Async component routes
export default ({ app }, inject) => {
	const routes = app.router.options.routes.reduce((routes, route) => {
		routes.push({
			name: route.name,
			path: route.path,
			async (routeTo, routeFrom, resolve) {
				route.component().then(c => resolve({ component: c }))
			}
		})

		return routes
	}, [])

	inject('routes', routes)
} */

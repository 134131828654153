<template>
	<f7-page id="add-address" class="bg-color-white">
		<f7-navbar back-link="Back">
			<div slot="title" class="text-capitalize">
				{{ this.$f7route.params.type }} Address
			</div>
			<f7-nav-right>
				<f7-link href="/locations" icon-f7="search" />
			</f7-nav-right>
		</f7-navbar>
		<google-map :map-config="mapConfig" :markers="markers" />
		<ValidationObserver ref="validator">
			<ValidationProvider rules="required">
				<input :value="location.line" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.city" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.pincode" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.landmark" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input :value="location.annotation" type="hidden">
			</ValidationProvider>
		</ValidationObserver>
		<f7-list class="m-0" no-hairlines-md>
			<f7-list-input
				label="Complete Address *"
				type="text"
				placeholder="Door No. / Floor / Building"
				:value="location.line"
				clear-button
				@input="location.line = $event.target.value"
				@input:clear="location.line = ''"
			/>
			<f7-list-input
				label="City *"
				type="text"
				placeholder="City"
				:value="location.city"
				clear-button
				@input="location.city = $event.target.value"
				@input:clear="location.city = ''"
			/>
			<f7-list-input
				label="Pincode *"
				type="text"
				placeholder="Pincode"
				:value="location.pincode"
				clear-button
				@input="location.pincode = $event.target.value"
				@input:clear="location.pincode = ''"
			/>
			<f7-list-input
				label="Landmark *"
				type="text"
				placeholder="Landmark"
				:value="location.landmark"
				clear-button
				@input="location.landmark = $event.target.value"
				@input:clear="location.landmark = ''"
			/>
			<f7-list-item>
				<small class="item-label">Address Category *</small>
			</f7-list-item>
			<f7-row class="px-3 pt-2 pb-3">
				<f7-col>
					<f7-button
						small
						round
						outline
						:fill="location.annotation === 'home'"
						@click="location.annotation = 'home'"
					>
						Home
					</f7-button>
				</f7-col>
				<f7-col>
					<f7-button
						small
						round
						outline
						:fill="location.annotation === 'work'"
						@click="location.annotation = 'work'"
					>
						Work
					</f7-button>
				</f7-col>
				<f7-col>
					<f7-button
						small
						round
						outline
						class="w-100"
						:fill="location.annotation === 'other'"
						@click="location.annotation = 'other'"
					>
						Other
					</f7-button>
				</f7-col>
			</f7-row>
		</f7-list>
		<div class="toolbar-button-container">
			<f7-button
				large
				fill
				:disabled="processing"
				@click="saveAddress"
			>
				Save
				<span v-if="$f7router.currentRoute.query.setDeliveryAddress === 'true'">
					&amp; Proceed
				</span>
			</f7-button>
		</div>
	</f7-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import GoogleMap from '~/components/google-map'

export default {
	components: {
		GoogleMap,
		ValidationObserver,
		ValidationProvider
	},
	props: {
		address: {
			type: Object,
			default: () => null
		}
	},
	data () {
		return {
			mapConfig: {
				zoom: 16,
				zoomControl: true,
				centerMarker: true,
				disableDefaultUI: true
			},
			markers: [],
			location: {
				line: '',
				area: '',
				region: '',
				city: '',
				pincode: '',
				landmark: '',
				annotation: 'home',
				lat: '',
				lng: ''
			}
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		},
		currentLocation () {
			return this.$store.state.location
		}
	},
	beforeMount () {
		if (this.address) {
			this.location = Object.assign({}, this.location, this.address.address)
		} else if (this.currentLocation) {
			this.location = Object.assign({}, this.location, this.currentLocation)
		}

		this.markers.push({
			position: {
				lat: parseFloat(this.location.lat),
				lng: parseFloat(this.location.lng)
			}
		})
	},
	mounted () {
		this.$root.$on('location', (response) => {
			this.location = Object.assign({}, this.location, response)
			this.markers = [
				{
					position: {
						lat: parseFloat(this.location.lat),
						lng: parseFloat(this.location.lng)
					}
				}
			]
		})
	},
	methods: {
		async saveAddress () {
			if (await this.$refs.validator.validate()) {
				const address = {
					id: this.address ? this.address.id : null,
					address: this.location,
					is_default: 0
				}

				this.processing = true
				this.$store.dispatch('saveAddress', address).then((response) => {
					if ([200, 201].includes(response.code)) {
						if (this.$f7router.currentRoute.query.setDeliveryAddress === 'true') {
							address.id = response.data.customer_addresses.id
							this.$store.commit('setDeliveryAddress', address)
						}

						this.$f7router.back({
							ignoreCache: true,
							force: true
						})
					}

					this.processing = false
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			} else {
				this.$f7.toast.create({
					text: 'Please fill all required fields.',
					closeTimeout: 3000
				}).open()
			}
		}
	}
}
</script>

<style lang="scss">
	#add-address {
		.page-content {
			padding-bottom: 81px;

			.item-input-wrap input {
				padding-right: 25px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>

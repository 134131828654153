<template>
	<div class="position-relative bg-color-white rounded elevation-4 p-3 mb-3">
		<input
			ref="input"
			:type="type"
			:name="name"
			:value="value"
			:placeholder="placeholder"
			autocomplete="off"
			class="w-100"
			:style="{
				paddingRight: type === 'password' ? '30px' : false
			}"
			:data-tooltip="tooltip"
			@input="updateValue"
			@focus="events.focus ? events.focus($event) : null"
			@blur="events.blur ? events.blur($event) : null"
		>
		<f7-link
			v-if="type === 'password'"
			class="eye"
			:icon-f7="showPassword ? 'eye_slash_fill' : 'eye_fill'"
			color="gray"
			@click="togglePassword"
		/>
		<small class="position-absolute text-color-red">{{ error }}</small>
	</div>
</template>

<script>
export default {
	name: 'CustomInput',
	$_veeValidate: {
		name () {
			return this.name
		},
		value () {
			return this.value
		}
	},
	props: {
		type: {
			type: String,
			default: 'text'
		},
		name: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		tooltip: {
			type: String,
			default: ''
		},
		error: {
			type: String,
			default: ''
		},
		events: {
			type: Object,
			default: () => ({})
		}
	},
	data () {
		return {
			showPassword: false
		}
	},
	methods: {
		togglePassword () {
			this.$refs.input.type = this.$refs.input.type === 'text' ? 'password' : 'text'
			this.showPassword = !this.showPassword
		},
		updateValue ($event) {
			this.$emit('input', $event.target.value)
		}
	}
}
</script>

<style lang="css" scoped>
	input {
		font-size: 16px;
	}

	small {
		bottom: 1px;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		display: -webkit-box;
	}

	.eye {
		position: absolute;
		top: 14px;
		right: 15px;
	}
</style>

import en from 'vee-validate/dist/locale/en.json'
import { setInteractionMode, extend, localize } from 'vee-validate'
import { required, email, min, max } from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('equals', {
	validate: (value, { other }) => value === other,
	message: 'The {_field_} does not match',
	params: [{ name: 'other', isTarget: true }]
})
extend('password', {
	validate: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()])(?=.{8,})/.test(value),
	message: 'Please enter a strong password'
})
extend('mobile', {
	message: field => `Please enter a valid ${field}`,
	validate: (value, countryCode) => {
		switch (countryCode[0]) {
		case 'IN':
			return /^[6-9]\d{9}$/.test(value)
		default:
			return true
		}
	}
})

localize({ en })

<template>
	<div
		:class="{
			'h-100': !loading && items.length === 0 && $f7route.name !== 'cart'
		}"
	>
		<div v-if="loading">
			<f7-card v-for="i in 3" :key="i" class="skeleton-text skeleton-effect-blink m-3">
				<f7-card-content class="p-0">
					<f7-row no-gap>
						<f7-col width="30">
							<f7-skeleton-block style="height: 88px" />
						</f7-col>
						<f7-col width="70">
							<div class="position-relative" style="height: 88px">
								<div class="display-flex justify-content-space-between align-items-center p-2">
									<div style="line-height: 1">
										<h4 class="text-capitalize m-0">
											Item Name
										</h4>
									</div>
									<span>Icon</span>
								</div>
								<f7-row
									class="position-absolute align-items-center p-2"
									style="left: 0; right: 0; bottom: 0"
									no-gap
								>
									<f7-col width="60">
										<p class="font-weight-bold m-0" style="bottom: 5px">
											Price Details
										</p>
									</f7-col>
									<f7-col
										width="40"
										class="display-flex justify-content-flex-end"
									>
										Add Button
									</f7-col>
								</f7-row>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div v-else-if="items.length">
			<f7-card
				v-for="(item, index) in items"
				:key="item.id"
				class="m-3"
				@click.native="showItemDetails(item)"
			>
				<f7-card-content class="p-0">
					<ribbon
						v-if="item.discount && item.discount.id"
						:text="`${item.discount.title} on coupon ${item.discount.code}`"
					/>
					<f7-row no-gap style="align-items: inherit">
						<f7-col width="30">
							<div
								:data-background="item.images[0] || fishPlaceholder"
								class="h-100 lazy lazy-fade-in"
								:style="{
									background: '#ccc',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									borderTopLeftRadius: item.discount && item.discount.id ? '0px' : '4px',
									borderBottomLeftRadius: '4px'
								}"
							/>
						</f7-col>
						<f7-col width="70">
							<div :class="`px-2 pb-2 pt-${item.discount && item.discount.id ? 4 : 2}`">
								<div class="display-flex justify-content-space-between align-items-center mb-3">
									<div style="line-height: 1">
										<h4 class="text-capitalize m-0">
											{{ item.name }}
										</h4>
										<small v-if="item.stockAdjusted" class="text-color-red">
											Stock Changed
											<f7-icon f7="exclamationmark_circle_fill" color="red" size="15px" />
										</small>
									</div>
									<f7-link
										v-if="$f7router.currentRoute.name !== 'cart' && !item.parent.id"
										:icon-f7="item.favourite_id ? 'heart_fill' : 'heart'"
										:icon-color="item.favourite_id ? 'pink' : ''"
										@click.stop="addToFavourites(item)"
									/>
									<f7-link
										v-else-if="$f7router.currentRoute.name === 'cart' && item.inventory.stock < settings.quantity"
										icon-f7="xmark_circle_fill"
										@click.stop="removeItem(index)"
									/>
								</div>
								<f7-row class="align-items-center mt-1" no-gap>
									<f7-col width="60">
										<p class="text-color-blue font-weight-bold m-0" style="bottom: 5px">
											<span v-if="item.items && item.items.length">
												{{ getPriceRange(item.items) }}
											</span>
											<span v-else>
												{{ item.inventory.price | currency }}
											</span>
											<span>/ {{ item.weight }} KG</span>
										</p>
									</f7-col>
									<f7-col
										v-if="isSelectedStallActive || $f7router.currentRoute.name === 'cart'"
										width="40"
										class="display-flex justify-content-flex-end"
									>
										<f7-button
											v-if="item.inventory.stock < settings.quantity"
											small
											round
											outline
											color="red"
											style="width: 105px"
										>
											SOLD OUT
										</f7-button>
										<f7-stepper
											v-else-if="cartItemsIndex[item.id] && cartItemsIndex[item.id].quantity"
											:ref="`item-${item.id}-button`"
											raised
											small
											fill
											round
											:step="item.unit_measure_type.short_name === 'count' ? 1 : parseFloat(settings.quantity)"
											:max="item.inventory.track_inventory ? (settings.quantity % 1 !== 0 ? (
												Math.floor(item.inventory.stock * 2) / 2
											) : Math.floor(item.inventory.stock)) : parseFloat(settings.order_weight_limit)"
											:value="cartItemsIndex[item.id].quantity"
											@stepper:change="addItem(item, $event)"
											@click.native.stop
										/>
										<f7-button
											v-else
											:ref="`item-${item.id}-button`"
											raised
											small
											fill
											round
											style="width: 105px"
											@click.stop="addItem(
												item,
												item.unit_measure_type.short_name === 'count' ? 1 : parseFloat(settings.quantity)
											)"
										>
											<span v-if="item.items && item.items.length && variationsInCart(item.items)">
												Modify
											</span>
											<span v-else>Add</span>
										</f7-button>
									</f7-col>
								</f7-row>
							</div>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div
			v-else-if="$f7route.name !== 'cart'"
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				No Items Found!
			</h2>
			<div
				v-if="location && nearbyStalls.length === 0 && noStallFeedback === null"
				class="text-align-center"
			>
				<p class="m-0">
					No stalls to serve at your location!
				</p>
				<p class="m-0">
					Do you want a new stall at your location?
				</p>
				<f7-row class="mt-3">
					<f7-col>
						<f7-button
							text="Yes"
							color="blue"
							small
							outline
							round
							@click="submitFeedback(true)"
						/>
					</f7-col>
					<f7-col>
						<f7-button
							text="No"
							color="red"
							small
							outline
							round
							@click="submitFeedback(false)"
						/>
					</f7-col>
				</f7-row>
			</div>
		</div>
	</div>
</template>

<script>
import Ribbon from '~/components/ribbon'
import fishPlaceholder from '~/assets/images/fish-placeholder.png'

export default {
	name: 'Items',
	components: {
		Ribbon
	},
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			required: true
		}
	},
	data () {
		return {
			fishPlaceholder,
			noStallFeedback: null
		}
	},
	computed: {
		settings () {
			return this.$store.state.settings
		},
		isLoggedIn () {
			return this.$store.state.isLoggedIn
		},
		location () {
			return this.$store.state.location
		},
		nearbyStalls () {
			return this.$store.state.nearbyStalls
		},
		cart: {
			get () {
				return this.$store.state.cart
			},
			set (cart) {
				this.$store.commit('setCart', cart)
			}
		},
		cartItemsIndex () {
			return this.cart.items.reduce((items, item, index) => {
				if ((this.cart.stall && this.cart.stall.id === this.selectedStall.id) ||
				this.$f7router.currentRoute.name === 'cart') {
					items[item.id] = {
						index,
						quantity: item.quantity
					}
				}

				return items
			}, {})
		},
		selectedItem: {
			get () {
				return this.$store.state.selectedItem
			},
			set (item) {
				this.$store.commit('setSelectedItem', item)
			}
		},
		selectedStall () {
			return this.$store.state.selectedStall
		},
		isSelectedStallActive () {
			return this.selectedStall ? this.isStallActive(this.selectedStall) : false
		}
	},
	watch: {
		noStallFeedback (feedback) {
			this.setLocalStorage('noStallFeedback', feedback.toString())
		}
	},
	mounted () {
		const feedback = this.getLocalStorage('noStallFeedback')

		if (feedback) {
			this.noStallFeedback = feedback === 'true'
		}
	},
	methods: {
		getPriceRange (items) {
			const prices = items.map(i => i.inventory.price).sort()

			if (prices.length > 1) {
				return `${
					this.$options.filters.currency(prices[0])
				} - ${
					this.$options.filters.currency(prices[items.length - 1])
				}`
			} else {
				return this.$options.filters.currency(prices[0])
			}
		},
		variationsInCart (items) {
			let inCart = false

			for (const i in items) {
				if (this.cartItemsIndex[items[i].id] && this.cartItemsIndex[items[i].id].quantity) {
					inCart = true
					break
				}
			}

			return inCart
		},
		checkWeight (weight) {
			let result = true

			if (this.settings.order_weight_limit && (
				(this.cart.weight + weight) > this.settings.order_weight_limit)
			) {
				result = false
			}

			return result
		},
		addItem (item, quantity) {
			if (item.items && item.items.length) {
				this.selectedItem = item
				return
			}

			const addItem = () => {
				const cartItemIndex = this.cart.items.findIndex(i => i.id === item.id)

				if (cartItemIndex !== -1) {
					if (quantity > 0 && this.checkWeight(item.weight * (
						quantity - this.cart.items[cartItemIndex].quantity
					))) {
						this.cart.items[cartItemIndex].quantity = quantity
					} else if (quantity <= 0) {
						this.cart.items.splice(cartItemIndex, 1)
					} else {
						this.$refs[`item-${item.id}-button`][0].setValue(this.cart.items[cartItemIndex].quantity)
						this.showCartFullAlert()
					}
				} else if (this.checkWeight(item.weight * quantity)) {
					this.cart.items.push({
						...item,
						quantity
					})
				} else {
					this.showCartFullAlert()
				}

				this.cart.stall = this.cart.items.length ? (this.cart.stall || this.selectedStall) : null
				this.$store.dispatch('cartCalculation')
			}

			if (this.$f7router.currentRoute.name !== 'cart' && this.cart.stall &&
			this.cart.stall.id !== this.selectedStall.id) {
				this.$f7.dialog.confirm('Your cart contains items from other location. Would you like to reset your cart for adding items from this location?', 'Items already in cart', () => {
					this.$store.commit('resetCart')
					addItem()
				})
			} else {
				addItem()
			}
		},
		removeItem (index) {
			this.cart.items.splice(index, 1)
			this.cart = Object.assign({}, this.cart)
			this.$store.dispatch('cartCalculation')
		},
		addToFavourites (item) {
			if (this.isLoggedIn) {
				this.$store.dispatch(
					item.favourite_id ? 'removeFavourite' : 'addToFavourites', item.favourite_id || item.id
				).then((response) => {
					if (response.code === 201) {
						item.favourite_id = response.data.customer_favourites.id
					} else if (response.code === 200) {
						item.favourite_id = null
					}
				}).catch(this.showErrors)
			} else {
				this.$f7router.navigate('/login')
			}
		},
		showItemDetails (item) {
			if (!['cart', 'items-id', 'stalls-id'].includes(this.$f7router.currentRoute.name)) {
				this.$store.commit('setNavigationProps', { item })
				this.$f7router.navigate(`/items/${item.id}`, {
					props: { item }
				})
			}
		},
		submitFeedback (response) {
			this.noStallFeedback = response
			this.$f7.dialog.alert(response ? 'Thank you! TNFDC is hearing.' : 'Thank you for your feedback!')

			if (response) {
				this.$store.dispatch('createConverstion', {
					conversation_status: 'open',
					conversation_type: 'request',
					subject: `Stall request for the location: ${this.location.address}`,
					entity: 'location',
					issue_slug: 'stall_request'
				})
			}
		}
	}
}
</script>

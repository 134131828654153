<template>
	<div>
		<div ref="googleMap" class="google-map" />
		<template v-if="google && map">
			<slot
				:google="google"
				:map="map"
			/>
		</template>
	</div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'

export default {
	props: {
		mapConfig: {
			type: Object,
			default: () => {
				return {}
			}
		},
		markers: {
			type: Array,
			default: () => {
				return []
			}
		},
		polylines: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	data () {
		return {
			google: null,
			map: null,
			bounds: null,
			mapMarkers: [],
			polyline: null
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	watch: {
		markers () {
			this.addMarkers()
		},
		polylines () {
			this.addPolyline()
		}
	},
	async mounted () {
		const googleMapApi = await GoogleMapsApiLoader({
			apiKey: process.env.GOOGLE_MAPS_API_KEY
		})

		this.google = googleMapApi
		this.initializeMap()
	},
	methods: {
		initializeMap () {
			const centerMarker = this.mapConfig.centerMarker

			delete this.mapConfig.centerMarker
			this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig)
			this.google.maps.event.addListener(this.map, 'zoom_changed', () => {
				const zoomChangeBoundsListener = this.google.maps.event.addListener(
					this.map,
					'bounds_changed',
					() => {
						if (this.map.getZoom() > 16) {
							this.map.setZoom(16)
						}

						this.google.maps.event.removeListener(zoomChangeBoundsListener)
					}
				)
			})

			this.addMarkers()
			this.addPolyline()

			if (centerMarker) {
				this.google.maps.event.addListener(this.map, 'drag', () => {
					this.mapMarkers[0].setPosition(this.map.getCenter())
				})

				this.google.maps.event.addListener(this.map, 'dragend', () => {
					this.processing = true
					this.$store.dispatch('getPlaceByGeocode', {
						latlng: `${this.mapMarkers[0].position.lat()},${this.mapMarkers[0].position.lng()}`
					}).then((response) => {
						if (response.status === 'OK') {
							this.$root.$emit('location', response.location)
						}

						this.processing = false
					})
				})
			}
		},
		addMarkers () {
			if (this.markers.length) {
				this.clearMarkers()
				this.bounds = new this.google.maps.LatLngBounds()
				this.markers.forEach((marker) => {
					const { Marker } = this.google.maps

					if (marker.iconConfig) {
						marker.icon = {
							url: marker.iconConfig.url,
							size: new this.google.maps.Size(marker.iconConfig.size.x, marker.iconConfig.size.y),
							anchor: marker.iconConfig.anchor
								? new this.google.maps.Point(marker.iconConfig.anchor.x, marker.iconConfig.anchor.y)
								: null,
							scaledSize: new this.google.maps.Size(marker.iconConfig.size.x, marker.iconConfig.size.y)
						}

						delete marker.iconConfig
					}

					this.mapMarkers.push(new Marker({
						...marker,
						map: this.map
					}))

					this.bounds.extend(marker.position)
				})

				this.map.fitBounds(this.bounds)
			}
		},
		clearMarkers () {
			for (let i = 0; i < this.mapMarkers.length; i++) {
				this.mapMarkers[i].setMap(null)
			}

			this.mapMarkers = []
		},
		addPolyline () {
			if (this.polylines.length) {
				this.clearPolyline()
				this.polyline = new this.google.maps.Polyline({
					path: this.polylines,
					strokeOpacity: 0,
					icons: [{
						icon: {
							path: 'M 0,-1 0,1',
							strokeOpacity: 1,
							scale: 4
						},
						offset: '0',
						repeat: '20px'
					}],
					map: this.map
				})
			}
		},
		clearPolyline () {
			if (this.polyline) {
				this.polyline.setMap(null)
			}
		}
	}
}
</script>

<style lang="css" scoped>
	.google-map {
		min-height: 40vh;
	}
</style>

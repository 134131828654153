<template>
	<f7-page id="faq" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar title="FAQ" back-link="Back" />
		<f7-block-title v-if="loading || faqs.data.length" class="mt-3">
			Frequently Asked Questions
		</f7-block-title>
		<f7-list v-if="loading" accordion-list class="skeleton-text skeleton-effect-blink my-0">
			<f7-list-item v-for="i in 6" :key="i" accordion-item title="xxxxx xxxxx xxxxx xxxxx xxxxx">
				<f7-accordion-content>
					<f7-block>
						<p>
							xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx
						</p>
					</f7-block>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
		<f7-list v-else-if="faqs.data.length" accordion-list class="my-0">
			<f7-list-item v-for="(faq, index) in faqs.data" :key="index" accordion-item :title="faq.title">
				<f7-accordion-content>
					<f7-block>
						<div v-html="faq.content" /> <!-- eslint-disable-line -->
					</f7-block>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				No FAQ's found!
			</h2>
		</div>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			loading: true,
			faqs: {
				data: [],
				pagination: null
			}
		}
	},
	beforeMount () {
		this.getFaqs({
			page_type: 'faq'
		})
	},
	methods: {
		getFaqs (filters) {
			this.$store.dispatch('getPages', filters).then((response) => {
				if (response.code === 200) {
					this.faqs.data = response.data.pages
					this.faqs.pagination = response.data.pagination
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		}
	}
}
</script>

<template>
	<f7-page
		id="coupons"
		infinite
		:infinite-distance="1"
		:infinite-preloader="coupons.pagination && coupons.pagination.current_page < coupons.pagination.last_page"
		@infinite="loadMoreResults('coupons', 'getCoupons')"
	>
		<f7-navbar title="Coupons" back-link="Back" />
		<div class="px-3 pt-3">
			<ValidationObserver ref="validator">
				<ValidationProvider
					ref="couponValidator"
					v-slot="{ errors }"
					name="coupon"
					rules="required"
				>
					<text-input
						v-model="couponCode"
						type="text"
						name="coupon"
						placeholder="Enter Coupon Code"
						:error="errors[0]"
					/>
				</ValidationProvider>
			</ValidationObserver>
			<f7-button
				large
				raised
				fill
				@click="applyCoupon"
			>
				Apply Coupon
			</f7-button>
		</div>
		<div v-if="loading" class="skeleton-text skeleton-effect-blink">
			<f7-block-title>
				xxxxx xxxxx xxxxx
			</f7-block-title>
			<f7-list media-list>
				<f7-list-item title="xxxxx">
					<span slot="subtitle">xxxxx xxxxx xxxxx</span>
					<div slot="text">
						xxxxx xxxxx xxxxx
					</div>
					<div slot="after">
						xxxxx
					</div>
				</f7-list-item>
				<f7-list-item title="xxxxx" link="#" />
				<f7-list-item title="xxxxx" link="#" />
			</f7-list>
		</div>
		<div v-else-if="coupons.data.length">
			<f7-block-title class="mt-3">
				More Coupons For You
			</f7-block-title>
			<f7-list media-list class="mb-0">
				<f7-list-item v-for="(coupon, index) in coupons.data" :key="index" no-chevron>
					<div slot="title" class="mb-1" style="line-height: 1.2">
						<p class="text-capitalize m-0">
							{{ coupon.title }}
						</p>
						<small>Code: {{ coupon.code }}</small>
					</div>
					<div v-if="coupon.custom_attributes.terms">
						<small
							v-for="(line, i) in coupon.custom_attributes.terms.split(/\n/).map(l => l.trim())"
							:key="i"
							class="display-block text-color-gray"
						>
							{{ line }}
						</small>
					</div>
					<div slot="after">
						<f7-link v-if="validateDiscount(coupon, cart, user)" link="#" text="APPLY" @click="applyDiscount(coupon)" />
						<p v-else class="text-color-red my-0">
							Not Applicable
						</p>
					</div>
				</f7-list-item>
			</f7-list>
		</div>
	</f7-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TextInput from '~/components/text-input'

export default {
	components: {
		TextInput,
		ValidationObserver,
		ValidationProvider
	},
	data () {
		return {
			loading: true,
			couponCode: '',
			coupons: {
				data: [],
				pagination: null
			}
		}
	},
	computed: {
		settings () {
			return this.$store.state.settings
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		},
		eligibleDiscounts: {
			get () {
				return this.$store.state.eligibleDiscounts
			},
			set (discounts) {
				this.$store.commit('setEligibleDiscounts', discounts)
			}
		},
		cart () {
			return this.$store.state.cart
		},
		user () {
			return this.$store.state.user
		},
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		}
	},
	mounted () {
		this.getCoupons()
	},
	methods: {
		getCoupons (filters = {}) {
			filters = Object.assign({
				location_id: this.cart.stall.id,
				only_coupon: 1
			}, filters)

			return this.$store.dispatch('getDiscounts', filters).then((response) => {
				if (response.code === 200) {
					this.coupons.data = filters.page ? this.coupons.data.concat(response.data.discounts) : response.data.discounts
					this.coupons.pagination = response.data.pagination
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		async applyCoupon () {
			if (await this.$refs.validator.validate()) {
				this.processing = true
				this.$store.dispatch('applyCoupon', {
					code: this.couponCode
				}).then((response) => {
					if (response.code === 200) {
						this.applyDiscount(response.data.discounts)
					}

					this.processing = false
				}).catch(() => {
					this.$refs.couponValidator.setErrors(['Coupon invalid or expired'])
					this.processing = false
				})
			}
		},
		applyDiscount (coupon) {
			if (this.validateDiscount(coupon, this.cart, this.user)) {
				this.eligibleDiscounts.push(coupon)

				if (coupon.items.length) {
					let quantity = parseFloat(coupon.range.item_qty)

					coupon.items.forEach((item) => {
						let weight = item.weight * quantity
						const cartItemIndex = this.cart.items.findIndex(i => i.id === item.id)

						if (cartItemIndex !== -1) {
							quantity = Math.max(this.cart.items[cartItemIndex].quantity, quantity)
							weight = item.weight * quantity

							if (!this.settings.order_weight_limit || (
								this.cart.weight + weight <= this.settings.order_weight_limit)
							) {
								this.cart.items[cartItemIndex].quantity = quantity
								this.cart.weight = parseFloat((this.cart.weight + weight).toFixed(3))
							} else {
								this.showCartFullAlert()
								return false
							}
						} else if (!this.settings.order_weight_limit || (
							this.cart.weight + weight <= this.settings.order_weight_limit)
						) {
							item.quantity = quantity
							this.cart.items.push(item)
							this.cart.weight = parseFloat((this.cart.weight + weight).toFixed(3))
						} else {
							this.showCartFullAlert()
							return false
						}
					})
				}

				this.$store.dispatch('cartCalculation')
				this.$f7router.back()
			} else {
				this.$refs.couponValidator.setErrors(['Coupon not applicable'])
			}
		}
	}
}
</script>

<style lang="scss">
	.terms .dialog-text {
		white-space: pre;
	}
</style>

<template>
	<f7-page id="pg">
		<f7-navbar
			title="Payment Gateway"
			:subtitle="`${cart.items.length} Item${cart.items.length === 1 ? '' : 's'}, To pay: ${
				$options.filters.currency(cart.price.total)
			}`"
		/>
		<div
			class="h-100 display-flex justify-content-center align-items-center px-3"
			style="flex-direction: column"
		>
			<f7-preloader v-if="loading" />
			<p v-if="loading" class="mb-0">
				{{ message }}, Please wait...
			</p>
			<div v-else-if="pgData">
				<p class="my-0">
					Please click on start payment to make payment of {{ cart.price.total | currency }}
				</p>
				<p class="mt-0">
					You will be redirected to the payment gateway.
				</p>
				<f7-button
					raised
					fill
					@click.once="startPayment"
				>
					Start Payment
				</f7-button>
			</div>
			<p v-if="loading && pgData" class="m-0">
				Do not press back button!
			</p>
		</div>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			loading: true,
			pgData: null,
			pgWindow: null,
			pgStatusInterval: null,
			message: 'Initializing payment gateway'
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		cart () {
			return this.$store.state.cart
		},
		paymentMethod () {
			return this.$store.state.paymentMethod
		}
	},
	mounted () {
		window.initPg = this.initPg
		window.closePg = this.closePg
		setTimeout(this.initPgRequest, 2000)
	},
	destroyed () {
		clearInterval(this.pgStatusInterval)
	},
	methods: {
		startPayment () {
			this.loading = true
			this.message = 'Redirecting to payment gateway'
			this.$bridge.initPg(
				this.$bridge.getName() === 'ANDROID' ? JSON.stringify(this.pgData) : this.pgData
			)

			setTimeout(() => {
				this.checkPaymentStatus()
				this.message = 'Waiting for payment gateway response'
			}, 2000)
		},
		initPgRequest () {
			const data = [
				process.env.WL_PG_MERCHANT_ID, `${this.paymentMethod.time}${this.$f7route.query.order_id}`,
				Math.round(this.cart.price.total * 100), 'INR', '', 'S', '', '', '',
				`${process.env.DOMAIN}/api/payments/wl/response`, this.paymentMethod.time, '', '', '', '', '', '', '', 'NA', 'NA'
			]

			this.$store.dispatch('generatePgRequest', {
				input: data.join('|')
			}).then((response) => {
				this.loading = false
				this.pgData = {
					url: '/api/payments/wl/pg',
					merchantRequest: response.merchant_request
				}
			}).catch((err) => {
				this.showErrors(err)
			})
		},
		initPg (data) {
			const url = data.url
			delete data.url
			const query = Object.keys(data).map(key => key + '=' + data[key]).join('&')

			this.pgWindow = window.open(`${url}?${query}`, '_blank')
		},
		closePg () {
			this.pgWindow.close()
		},
		checkPaymentStatus () {
			let attempt = 0
			let checking = false
			this.pgStatusInterval = setInterval(() => {
				if (!checking) {
					checking = true
					this.$store.dispatch('checkPaymentStatus').then((response) => {
						if (['S', 'F'].includes(response.status) || attempt >= 100) {
							clearInterval(this.pgStatusInterval)
							this.$bridge.closePg()
							setTimeout(() => {
								if (response.status === 'S') {
									this.$store.dispatch('sendSms', {
										phone: this.user.phone,
										message: `Your Meengal order no. #${this.$f7route.query.order_id} for Rs. ${
											this.cart.price.total
										}. Track your order here: https://meengal.com/o/t/${
											this.$f7route.query.order_id
										}`
									})

									this.$f7router.navigate(`/orders/${this.$f7route.query.order_id}`, {
										clearPreviousHistory: true
									})

									this.$store.commit('resetCart')
									this.$store.commit('setPaymentMethod', null)
									this.$store.commit('setDeliveryAddress', null)
									this.$store.commit('setEligibleDiscounts', [])
								} else {
									this.$f7router.back()
									this.$f7.toast.create({
										text: 'Payment failed!, Please try again.',
										closeTimeout: 3000
									}).open()
								}
							}, 3000)
						} else if (this.pgWindow && this.pgWindow.closed) {
							this.$store.dispatch('checkPgStatus', {
								input: `${process.env.WL_PG_MERCHANT_ID}|${this.paymentMethod.time}${
									this.$f7route.query.order_id
								}|||||||||||`
							}).catch((err) => {
								console.error(err) // eslint-disable-line
							})
						}

						checking = false
					}).catch((err) => {
						console.error(err) // eslint-disable-line
						checking = false
					})
				}

				attempt++
			}, 3000)
		}
	}
}
</script>

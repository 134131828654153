const state = () => ({
	settings: {
		quantity: 1
	},
	user: null,
	isLoggedIn: false,
	processing: false,
	allowLoadMore: true,
	location: null,
	nearbyStalls: [],
	selectedStall: null,
	selectedItem: null,
	eligibleDiscounts: [],
	cart: {
		stall: null,
		items: [],
		weight: 0,
		price: {
			subTotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: [],
			discount: 0,
			discounts: [],
			total: 0
		}
	},
	addresses: [],
	deliveryAddress: null,
	paymentMethod: null,
	navigationProps: {}
})

export default state

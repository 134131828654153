<template>
	<f7-page id="order-detail" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar back-link="Back" @click:back="$f7router.back('/orders')">
			<f7-nav-title style="line-height: 1">
				<p class="m-0">
					Order# {{ $f7route.params.id }}
				</p>
				<small v-if="order" style="font-size: 14px">{{ order.items.length }} Item{{ order.items.length === 1 ? '' : 's' }} | {{ order.total | currency }}</small>
			</f7-nav-title>
			<f7-nav-right v-if="order">
				<f7-link :href="`/orders/${$f7route.params.id}/help`" icon-f7="question_circle" />
			</f7-nav-right>
		</f7-navbar>
		<div v-if="loading" class="skeleton-text skeleton-effect-blink">
			<div class="timeline my-3">
				<div v-for="i in 2" :key="i" class="timeline-item">
					<div class="timeline-item-date">
						xx xxx
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-time">
							xxxxxxx
						</div>
						<div class="timeline-item-title">
							xxxxx
						</div>
						<div class="timeline-item-text">
							xxxxx xxxxx xxxxx xxxxx
						</div>
					</div>
				</div>
			</div>
			<p class="mx-3 my-0">
				xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx xxxxx
			</p>
			<div v-for="i in 2" :key="i">
				<f7-block-title>xxxxx</f7-block-title>
				<f7-list simple-list>
					<f7-list-item v-for="j in 3" :key="j">
						<f7-row class="w-100 justify-content-between">
							<f7-col>
								xxxxx xxxxx
							</f7-col>
							<f7-col>xx</f7-col>
							<f7-col>
								<p class="float-right m-0">
									xxxxx
								</p>
							</f7-col>
						</f7-row>
					</f7-list-item>
				</f7-list>
			</div>
		</div>
		<div v-else-if="order">
			<div class="timeline my-3">
				<div class="timeline-item">
					<div class="timeline-item-date">
						<f7-icon material="store" />
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-title text-capitalize">
							{{ order.store_details.name }}
						</div>
						<div class="timeline-item-text">
							{{ order.store_details.line }}
						</div>
					</div>
				</div>
				<div class="timeline-item">
					<div class="timeline-item-date">
						<f7-icon :material="order.shipping_address.annotation === 'other' ? 'location_city' : order.shipping_address.annotation" />
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-title text-capitalize">
							{{ order.shipping_address.annotation }}
						</div>
						<div class="timeline-item-text">
							{{ order.shipping_address.line }}
						</div>
					</div>
				</div>
			</div>
			<f7-row class="align-items-center mx-3">
				<f7-col width="10">
					<f7-icon
						v-if="[
							'pending', 'payment_authorized', 'cod_authorized'
						].includes(order.order_status.short_name)"
						material="access_time"
					/>
					<f7-icon
						v-if="[
							'confirmed', 'runner_cancelled'
						].includes(order.order_status.short_name)"
						material="thumb_up"
					/>
					<f7-icon v-if="order.order_status.short_name === 'dispatched'" material="local_shipping" />
					<f7-icon v-if="order.order_status.short_name === 'completed'" material="done" />
					<f7-icon v-if="order.order_status.short_name === 'cancelled'" material="thumb_down" />
					<f7-icon v-if="order.order_status.short_name === 'refunded'" material="undo" />
				</f7-col>
				<f7-col width="90">
					<p class="m-0">
						Your order
						<span
							v-if="[
								'pending', 'payment_authorized', 'cod_authorized'
							].includes(order.order_status.short_name)"
						>
							is placed and being processed.
						</span>
						<span
							v-else-if="[
								'confirmed', 'runner_cancelled'
							].includes(order.order_status.short_name)"
						>
							has been confirmed and is being prepared.
						</span>
						<span v-else-if="order.order_status.short_name === 'dispatched'">
							has been dispatched.
						</span>
						<span v-else-if="order.order_status.short_name === 'completed'">
							has been delivered and completed.
						</span>
						<span v-else-if="order.order_status.short_name === 'cancelled'">
							has been cancelled.
						</span>
						<span v-else-if="order.order_status.short_name === 'refunded'">
							has been cancelled and refunded.
						</span>
					</p>
				</f7-col>
			</f7-row>
			<div v-if="order && order.feedback.rating">
				<f7-block-title class="mt-3 mb-2">
					Your rating
				</f7-block-title>
				<div class="display-flex align-items-center px-3">
					<span class="mr-1" style="color: #FFC107">&starf;</span>
					<span>{{ order.feedback.rating }} | {{ feedback.messages[order.feedback.rating] }}</span>
				</div>
			</div>
			<f7-button
				v-else-if="order && order.order_status.short_name === 'completed'"
				class="mt-3 mx-3"
				outline
				@click="feedback.show = true"
			>
				Rate Order
			</f7-button>
			<f7-button
				v-else-if="order && ![
					'cancelled', 'refunded'
				].includes(order.order_status.short_name)"
				:href="`/orders/${$f7route.params.id}/track`"
				class="mt-3 mx-3"
				outline
			>
				Track Order
			</f7-button>
			<div v-if="order.active_conversations.length">
				<f7-block-title class="mt-3">
					Active Conversations
				</f7-block-title>
				<f7-list class="mb-0">
					<f7-list-item
						v-for="(conversation, index) in order.active_conversations"
						:key="index"
						:link="`/conversations/${conversation.id}`"
						:route-props="{ orderId: order.id }"
						:title="conversation.issue.name"
					>
						<f7-icon slot="media" f7="chat_bubble_text" />
					</f7-list-item>
				</f7-list>
			</div>
			<f7-block-title class="mt-3">
				Items
			</f7-block-title>
			<f7-list class="mb-0" simple-list>
				<f7-list-item v-for="(item, index) in order.items" :key="index">
					<f7-row class="w-100 justify-content-between">
						<f7-col width="50" class="word-wrap">
							{{ item.item_details.item_name }}
						</f7-col>
						<f7-col width="25" class="text-align-center">
							x{{ item.qty }}
						</f7-col>
						<f7-col width="25">
							<p class="float-right m-0">
								{{ item.sub_total | currency }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list-item>
			</f7-list>
			<f7-block-title class="mt-3">
				Bill Details
			</f7-block-title>
			<f7-list class="mb-0" simple-list>
				<f7-list-item>
					<f7-row class="w-100 justify-content-between">
						<f7-col>
							Sub Total
						</f7-col>
						<f7-col>
							<p class="float-right m-0">
								{{ order.sub_total | currency }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list-item>
				<f7-list-item v-if="order.tax">
					<f7-row class="w-100 justify-content-between">
						<f7-col>
							Tax
						</f7-col>
						<f7-col>
							<p class="float-right m-0">
								{{ order.tax | currency }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list-item>
				<f7-list-item v-if="order.delivery_fee">
					<f7-row class="w-100 justify-content-between">
						<f7-col>
							Delivery Fee
						</f7-col>
						<f7-col>
							<p class="float-right m-0">
								{{ order.delivery_fee | currency }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list-item>
				<f7-list-item v-if="order.discount">
					<f7-row class="w-100 justify-content-between">
						<f7-col>
							Discount
						</f7-col>
						<f7-col>
							<p class="float-right m-0">
								- {{ order.discount | currency }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list-item>
				<f7-list-item>
					<f7-row class="w-100 justify-content-between">
						<f7-col>
							Total (Paid via {{ order.payment_method.name }})
						</f7-col>
						<f7-col>
							<p class="float-right m-0">
								{{ order.total | currency }}
							</p>
						</f7-col>
					</f7-row>
				</f7-list-item>
			</f7-list>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2 class="mb-0">
				Order Not Found!
			</h2>
			<p class="m-0">
				Please try again.
			</p>
		</div>
		<f7-popup
			v-if="order"
			id="feedback"
			:opened="feedback.show"
			@popup:closed="feedback.show = false"
		>
			<f7-page>
				<f7-navbar :title="`Order from ${order.store_details.name}`">
					<f7-nav-right>
						<f7-link color="white" popup-close>
							Close
						</f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-block-title class="mt-3">
					Overall Rating
				</f7-block-title>
				<f7-card>
					<f7-card-content>
						<h2 class="text-align-center">
							{{ feedback.messages[feedback.rating] }}
						</h2>
						<star-rating
							v-model="feedback.rating"
							class="justify-content-center"
							:show-rating="false"
						/>
					</f7-card-content>
				</f7-card>
				<f7-card>
					<f7-card-content>
						<h4 class="text-align-center mt-0">
							<span v-if="feedback.rating < 3">What went wrong?</span>
							<span v-else-if="feedback.rating > 3">What did the stall impress you with?</span>
							<span v-else>What can the stall improve?</span>
						</h4>
						<f7-row>
							<f7-col>
								<f7-button
									:fill="feedback.impressions.includes('quality')"
									outline
									@click="setImpression('quality')"
								>
									Quality
								</f7-button>
							</f7-col>
							<f7-col>
								<f7-button
									:fill="feedback.impressions.includes('package')"
									outline
									@click="setImpression('package')"
								>
									Package
								</f7-button>
							</f7-col>
							<f7-col>
								<f7-button
									:fill="feedback.impressions.includes('delivery')"
									outline
									@click="setImpression('delivery')"
								>
									Delivery
								</f7-button>
							</f7-col>
						</f7-row>
					</f7-card-content>
				</f7-card>
				<f7-card>
					<f7-card-content>
						<f7-list no-hairlines-md>
							<f7-list-input
								type="textarea"
								resizable
								:placeholder="feedback.rating < 3 ? 'Tell us more..' : (
									feedback.rating > 3 ? 'Tell us what you liked..' : 'Tell us your suggestions..'
								)"
								@input="feedback.remarks = $event.target.value"
							/>
							<p class="mt-0 px-3" style="font-size: 12px">
								TNFDC is listening to your valuble feedback..
							</p>
						</f7-list>
					</f7-card-content>
				</f7-card>
				<f7-button
					style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: unset; z-index: 1"
					large
					round
					fill
					@click="submitFeedback"
				>
					Submit Your Feedback
				</f7-button>
			</f7-page>
		</f7-popup>
	</f7-page>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
	components: {
		StarRating
	},
	data () {
		return {
			loading: true,
			order: null,
			feedback: {
				show: false,
				rating: 5,
				messages: {
					1: 'VERY BAD',
					2: 'BAD',
					3: 'AVERAGE',
					4: 'GOOD',
					5: 'LOVED IT'
				},
				impressions: [],
				remarks: ''
			}
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	beforeMount () {
		this.getOrderDetails()
	},
	methods: {
		getOrderDetails () {
			this.$store.dispatch('getOrderDetails', this.$f7route.params.id).then((response) => {
				if (response.code === 200) {
					this.order = response.data.orders
				} else {
					this.$f7router.navigate('/orders', { clearPreviousHistory: true })
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		setImpression (impression) {
			const index = this.feedback.impressions.indexOf(impression)

			if (index !== -1) {
				this.feedback.impressions.splice(index, 1)
			} else {
				this.feedback.impressions.push(impression)
			}
		},
		submitFeedback () {
			this.processing = true
			this.$store.dispatch('submitFeedback', {
				order_id: this.order.id,
				rating: this.feedback.rating,
				feedback: {
					impressions: this.feedback.impressions,
					remarks: this.feedback.remarks
				}
			}).then(() => {
				this.processing = false
				this.feedback.show = false
				this.refresh()
			}).catch((err) => {
				this.processing = false
				this.showErrors(err)
			})
		}
	}
}
</script>

<style lang="scss">
	#feedback {
		.page-content {
			padding-bottom: 48px;
		}
	}
</style>

<template>
	<f7-page id="delivery-location">
		<f7-navbar title="Set Delivery Location" back-link="Back">
			<f7-subnavbar :inner="false">
				<f7-searchbar
					search-container=".search-list"
					placeholder="Search Location"
					custom-search
					@input="getPlaces"
				/>
			</f7-subnavbar>
		</f7-navbar>
		<f7-block-title class="mt-3">
			Places
		</f7-block-title>
		<f7-list class="search-list searchbar-found" media-list>
			<f7-list-item
				title="Current Location"
				text="Using GPS"
				link="#"
				no-chevron
				@click="getCurrentLocation"
			>
				<f7-icon slot="media" f7="scope" />
			</f7-list-item>
			<f7-list-item
				v-for="(place, index) in places"
				:key="index"
				:title="place.structured_formatting.main_text"
				:text="place.description"
				link="#"
				no-chevron
				@click="setDeliveryLocation({
					placeId: place.place_id
				})"
			>
				<f7-icon slot="media" f7="placemark" />
			</f7-list-item>
		</f7-list>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			places: [],
			timer: null,
			token: null
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	beforeMount () {
		this.generateToken()
	},
	mounted () {
		window.emitCurrentLocation = this.emitCurrentLocation
	},
	methods: {
		generateToken () {
			this.token = this.$f7.utils.id('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx')
		},
		getPlaces ($event) {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				if ($event.target.value) {
					this.processing = true
					this.$store.dispatch('getPlaces', {
						input: $event.target.value,
						sessiontoken: this.token
					}).then((response) => {
						if (response.status === 'OK') {
							this.places = response.predictions
						} else {
							throw new Error(response.status)
						}

						this.processing = false
					}).catch((err) => {
						this.showErrors(err)
						this.processing = false
					})
				} else {
					this.generateToken()
				}
			}, 500)
		},
		setDeliveryLocation (data) {
			this.processing = true
			this.$store.dispatch(data.placeId ? 'getPlace' : 'getPlaceByGeocode', Object.assign({
				save: ['/intro', '/home'].includes(this.$f7router.previousRoute.path)
			}, {
				place_id: data.placeId || null,
				sessiontoken: data.placeId ? this.token : null,
				latlng: data.coordinates || null
			})).then((response) => {
				if (response.status === 'OK') {
					this.$root.$emit('location', response.location)

					if (['intro', 'home'].includes(this.$f7router.previousRoute.name) || (
						this.$f7router.previousRoute.name === 'addresses-type' && !data.placeId
					)) {
						this.$store.commit('setLocation', response.location)
					}

					return Promise.resolve()
				} else {
					throw new Error(response.status)
				}
			}).then(() => {
				if (this.$f7router.previousRoute.path === '/intro') {
					this.$f7router.navigate('/home', {
						clearPreviousHistory: true
					})
				} else {
					this.$f7router.back(this.$f7router.previousRoute.path, {
						force: this.$f7router.previousRoute.name !== 'addresses-type'
					})
				}

				this.processing = false
			}).catch((err) => {
				this.showErrors(err)
				this.processing = false
			})

			if (data.placeId) {
				this.generateToken()
			}
		},
		getCurrentLocation () {
			this.processing = true
			this.$bridge.getCurrentLocation()
		},
		emitCurrentLocation (coordinates) {
			this.processing = false

			if (coordinates) {
				if (typeof coordinates === 'string') {
					coordinates = JSON.parse(coordinates)
				}

				this.setDeliveryLocation({
					coordinates: `${coordinates.lat},${coordinates.lng}`
				})
			} else {
				this.$f7.toast.create({
					text: "Sorry! we couldn't get your location. Try searching your location manually.",
					closeTimeout: 3000
				}).open()
			}
		}
	}
}
</script>

<style lang="scss">
	#delivery-location {
		.list .item-text {
			-webkit-line-clamp: initial;
			max-height: none;
		}
	}
</style>

<template>
	<f7-page
		id="home"
		ptr
		:ptr-distance="60"
		infinite
		:infinite-distance="1"
		:infinite-preloader="items.pagination && items.pagination.current_page < items.pagination.last_page"
		@ptr:refresh="refresh"
		@infinite="loadMoreResults('items', 'getItems')"
	>
		<f7-navbar>
			<f7-nav-left>
				<f7-link panel-open="left" icon-f7="bars" />
			</f7-nav-left>
			<f7-nav-title>
				<p class="m-0" @click="$f7router.navigate('/locations')">
					{{ location ? (location.area || location.city || (
						location.line ? location.line.split(', ')[0] : 'Unnamed Location'
					)) : 'Set Location' }}
				</p>
			</f7-nav-title>
			<f7-nav-right>
				<f7-link href="/items?type=search%20items" icon-f7="search" />
			</f7-nav-right>
		</f7-navbar>
		<h4 v-if="loading || popularItems.length > 0" class="px-3">
			Popular Items
			<f7-link href="/items?type=popular%20items" text="View All" class="float-right" />
		</h4>
		<f7-swiper
			:params="{
				slidesPerView: 'auto',
				spaceBetween: 30,
				preloadImages: false,
				lazy: true
			}"
			class="px-3"
		>
			<f7-swiper-slide
				v-for="(item, index) in loading ? [1, 2, 3] : popularItems"
				:key="`popular-item-${index}`"
				class="w-75 pb-1"
			>
				<f7-card v-if="loading" class="skeleton-text skeleton-effect-blink elevation-2 m-0">
					<f7-skeleton-block style="height: 150px" />
					<f7-card-content class="p-2">
						<h4 class="m-0">
							Item Name
						</h4>
						<h4 class="m-0">
							Price Details
						</h4>
					</f7-card-content>
				</f7-card>
				<f7-card
					v-else
					class="elevation-2 m-0"
					@click.native="showItemDetails(item)"
				>
					<ribbon
						v-if="item.discount && item.discount.id"
						:text="`${item.discount.title} on coupon ${item.discount.code}`"
					/>
					<f7-card-header
						:data-background="item.images[0] || fishPlaceholder"
						class="lazy lazy-fade-in no-border"
						valign="bottom"
						style="height: 150px; background: #ccc; background-size: cover; background-repeat: no-repeat; background-position: center"
					/>
					<f7-card-content class="p-2">
						<h4 class="m-0">
							{{ item.name }}
						</h4>
						<h4 class="text-color-blue m-0">
							{{ item.inventory.price | currency }}
							<span v-if="item.unit_measure_type.short_name === 'weight'"> / KG</span>
						</h4>
					</f7-card-content>
				</f7-card>
			</f7-swiper-slide>
		</f7-swiper>
		<h4 v-if="loading || nearbyStalls.length > 0" class="px-3">
			<span v-if="loading">Nearby Stalls</span>
			<span v-else-if="nearbyStalls.length">
				Found {{ nearbyStalls.length }} Nearby Stall{{ nearbyStalls.length === 1 ? '' : 's' }}
			</span>
		</h4>
		<f7-swiper
			id="stalls-swiper"
			:params="{
				slidesPerView: 'auto',
				spaceBetween: 10
			}"
			class="px-3"
		>
			<f7-swiper-slide
				v-for="(stall, index) in loading ? [1, 2, 3] : nearbyStalls"
				:key="`stall-${index}`"
			>
				<f7-chip v-if="loading" text="Stall Location Name" class="w-100 skeleton-text skeleton-effect-blink text-color-black bg-color-white elevation-2">
					<f7-skeleton-block slot="media" style="height: 32px; width: 32px; border-radius: 50%" />
				</f7-chip>
				<f7-chip
					v-else
					:text="stall.name"
					class="w-100 text-color-black bg-color-white elevation-2"
					:class="{
						active: selectedStall && selectedStall.id === stall.id
					}"
					@click="setSelectedStall(stall)"
				>
					<f7-icon slot="media" material="store" />
				</f7-chip>
			</f7-swiper-slide>
		</f7-swiper>
		<small
			v-if="!loading && selectedStall && !isSelectedStallActive"
			class="display-block text-color-red px-3 my-3"
		>
			Online orders have closed at {{ selectedStall.name }}. {{ nextStallOpeningTime(selectedStall) }}
		</small>
		<small
			v-else-if="!loading && selectedStall && !selectedStall.delivery_stock"
			class="display-block text-color-red px-3 my-3"
		>
			Due to high demand in your area, delivery partners are busy! Please try after some time.
		</small>
		<items :loading="loading" :items="items.data" />
		<variations />
		<cart-toolbar />
	</f7-page>
</template>

<script>
import fishPlaceholder from '~/assets/images/fish-placeholder.png'
import Items from '~/components/items'
import Ribbon from '~/components/ribbon'
import Variations from '~/components/variations'
import CartToolbar from '~/components/cart-toolbar'

export default {
	components: {
		Items,
		Ribbon,
		Variations,
		CartToolbar
	},
	data () {
		return {
			loading: true,
			items: {
				data: [],
				pagination: null
			},
			popularItems: [],
			fishPlaceholder
		}
	},
	computed: {
		settings () {
			return this.$store.state.settings
		},
		user () {
			return this.$store.state.user
		},
		isLoggedIn () {
			return this.$store.state.isLoggedIn
		},
		location () {
			return this.$store.state.location
		},
		nearbyStalls: {
			get () {
				return this.$store.state.nearbyStalls
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'nearbyStalls',
					value
				})
			}
		},
		selectedStall: {
			get () {
				return this.$store.state.selectedStall
			},
			set (stall) {
				this.$store.commit('setSelectedStall', stall)
			}
		},
		isSelectedStallActive () {
			return this.selectedStall ? this.isStallActive(this.selectedStall) : false
		},
		cart: {
			get () {
				return this.$store.state.cart
			},
			set (cart) {
				this.$store.commit('setCart', cart)
			}
		},
		selectedItem: {
			get () {
				return this.$store.state.selectedItem
			},
			set (item) {
				this.$store.commit('setSelectedItem', item)
			}
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		},
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		}
	},
	mounted () {
		if (this.isLoggedIn && this.$f7route.query.track) {
			this.loading = false
			window.history.replaceState({}, document.title, '/')

			return this.$f7router.navigate(`/orders/${this.$f7route.query.track}/track`, {
				clearPreviousHistory: true
			})
		}

		if (!this.location) {
			this.loading = false
			setTimeout(() => this.$f7router.navigate('/locations'), 500)

			return false
		}

		this.$store.dispatch('getNearbyStalls').then(() => this.getItems()).catch(() => {
			this.loading = false
		})

		if (this.settings.alert_message) {
			setTimeout(() => {
				this.$f7.notification.create({
					icon: '<img src="/images/logo.png">',
					title: '<span class="ml-2">Meengal</span>',
					subtitle: `Dear ${this.user ? this.user.name : 'User'},`,
					text: this.settings.alert_message,
					closeButton: true,
					on: {
						close: () => {
							delete this.settings.alert_message
						}
					}
				}).open()
			}, 3000)
		}
	},
	methods: {
		getItems (filters = {}) {
			filters = Object.assign({
				is_active: 1
			}, filters)

			return this.$store.dispatch('getItems', filters).then((response) => {
				this.loading = false

				if (response.code === 200) {
					this.items.data = filters.page ? this.items.data.concat(response.data.items) : response.data.items
					this.items.pagination = response.data.pagination

					if (this.popularItems.length === 0) {
						this.popularItems = Object.assign([], this.items.data)
							.sort(() => 0.5 - Math.random()).slice(0, 3)
					}

					this.$nextTick(() => this.$f7.lazy.create(this.$$('#home')[0]))
				}
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		setSelectedStall (stall) {
			if (!this.selectedStall || this.selectedStall.id !== stall.id) {
				this.selectedStall = stall
				this.processing = true
				this.getItems().then(() => {
					this.processing = false
				})
			}
		},
		showItemDetails (item) {
			this.$store.commit('setNavigationProps', { item })
			this.$f7router.navigate(`/items/${item.id}`, {
				props: { item }
			})
		}
	}
}
</script>

<style lang="scss">
	#home {
		.navbar .title {
			border-bottom: 1px dotted;
		}

		#stalls-swiper .swiper-slide {
			width: auto !important;
		}
	}
</style>

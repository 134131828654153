<template>
	<f7-page id="edit-profile">
		<f7-navbar title="Edit Profile" back-link="Back" />
		<div class="display-flex justify-content-center mt-3 mb-3">
			<div
				class="display-flex align-items-center justify-content-center"
				:style="{
					height: '100px',
					width: '100px',
					background: 'linear-gradient(to bottom, #39C0EE, #0AA4DA)',
					borderRadius: '50%',
					fontSize: '50px',
					color: 'white'
				}"
			>
				{{ user.name[0] }}
			</div>
		</div>
		<h2 class="text-align-center mt-0 mb-3">
			{{ user.name }}
		</h2>
		<ValidationObserver ref="validator">
			<ValidationProvider rules="required">
				<input v-model="name" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input v-model="gender" type="hidden">
			</ValidationProvider>
			<ValidationProvider rules="required">
				<input v-model="dob" type="hidden">
			</ValidationProvider>
		</ValidationObserver>
		<f7-list no-hairlines-md class="m-0">
			<f7-list-input
				label="Name *"
				type="text"
				placeholder="Your name"
				clear-button
				:value="name"
				@input="name = $event.target.value"
			>
				<f7-icon slot="media" material="person" />
			</f7-list-input>
			<f7-list-input
				label="Gender *"
				type="select"
				:value="gender"
				@input="gender = $event.target.value"
			>
				<f7-icon slot="media" material="people" />
				<option value="">
					Select your gender
				</option>
				<option value="male">
					Male
				</option>
				<option value="female">
					Female
				</option>
			</f7-list-input>
			<f7-list-input
				label="Birthday *"
				type="datepicker"
				placeholder="Your birthday"
				readonly
				:calendar-params="{
					header: true,
					footer: true,
					dateFormat: 'dd M yyyy',
					value: dob ? [new Date(dob)] : [],
					minDate: minDate,
					maxDate: maxDate
				}"
				@calendar:change="dob = $moment($event[0]).format('YYYY-MM-DD')"
			>
				<f7-icon slot="media" material="cake" />
			</f7-list-input>
		</f7-list>
		<f7-button
			large
			fill
			style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: unset"
			:disabled="processing"
			@click="editProfile"
		>
			Save
		</f7-button>
	</f7-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
	components: {
		ValidationObserver,
		ValidationProvider
	},
	data () {
		return {
			name: '',
			gender: '',
			dob: '',
			minDate: new Date(),
			maxDate: new Date()
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	beforeMount () {
		this.name = this.user.name || ''
		this.gender = this.user.gender || ''
		this.dob = this.user.dob || ''
		this.minDate = this.minDate.setFullYear(this.minDate.getFullYear() - 100)
		this.maxDate = this.maxDate.setFullYear(this.maxDate.getFullYear() - 18)
	},
	methods: {
		async editProfile () {
			if (await this.$refs.validator.validate()) {
				this.processing = true
				this.$store.dispatch('updateProfile', {
					name: this.name,
					gender: this.gender,
					dob: this.dob
				}).then((response) => {
					if (response.code === 200) {
						this.$f7router.back()
						this.$f7.toast.create({
							text: 'Profile updated successfully',
							closeTimeout: 3000
						}).open()
					}

					this.processing = false
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			} else {
				this.$f7.toast.create({
					text: 'Please fill all required fields.',
					closeTimeout: 3000
				}).open()
			}
		}
	}
}
</script>

<style lang="scss">
	#edit-profile {
		.page-content {
			padding-bottom: 48px;
		}
	}
</style>

<template>
	<f7-page
		id="stall-details"
		ref="page"
		infinite
		:infinite-distance="1"
		:infinite-preloader="items.pagination && items.pagination.current_page < items.pagination.last_page"
		@infinite="loadMoreResults('items', 'getStallDetails')"
	>
		<f7-photo-browser
			ref="photos"
			type="popup"
			theme="dark"
			:photos="stall.location.images"
		/>
		<div class="position-fixed" style="top: 0; left: 0; right: 0; background-color: #F5F5F5; z-index: 2">
			<div
				:data-background="stall.location.images.length ? stall.location.images[0] : fishPlaceholder"
				class="position-relative lazy lazy-fade-in"
				style="height: 200px; background: #ccc; background-size: cover; background-repeat: no-repeat; background-position: center"
				@click="$refs.photos.open(0)"
			>
				<f7-link
					href="#"
					class="color-white"
					style="position: absolute; right: 15px; top: 15px"
					icon-f7="xmark_circle_fill"
					back
					@click.stop="$store.commit('removeNavigationProp', 'stall')"
				/>
				<f7-row class="w-100 position-absolute p-2" style="background: rgb(0, 0, 0, .5); bottom: 0px">
					<f7-col width="25" class="text-align-center">
						<f7-icon f7="placemark" color="white" class="mb-1" />
						<small class="display-block text-color-white font-weight-bold">
							{{ calculateDistance(
								location.lat, location.lng, stall.location.lat, stall.location.lng
							) | currency({
								symbol: ''
							}) }} km
						</small>
					</f7-col>
					<f7-col width="75">
						<h4 class="text-capitalize text-color-white m-0">
							{{ stall.location.name }}
							<span v-if="!isSelectedStallActive">- Unserviceable</span>
						</h4>
						<p class="text-capitalize text-color-white m-0">
							{{ stall.location.address.line }}
						</p>
					</f7-col>
				</f7-row>
			</div>
		</div>
		<items :loading="loading" :items="items.data" />
		<variations />
		<cart-toolbar />
	</f7-page>
</template>

<script>
import Items from '~/components/items'
import Variations from '~/components/variations'
import CartToolbar from '~/components/cart-toolbar'
import fishPlaceholder from '~/assets/images/fish-placeholder.png'

export default {
	components: {
		Items,
		Variations,
		CartToolbar
	},
	data () {
		return {
			stall: null,
			fishPlaceholder,
			loading: true,
			items: {
				data: [],
				pagination: null
			}
		}
	},
	computed: {
		nearbyStalls () {
			return this.$store.state.nearbyStalls
		},
		stallProp () {
			return this.$store.getters.navigationProp('stall')
		},
		location () {
			return this.$store.state.location
		},
		cart () {
			return this.$store.state.cart
		},
		selectedStall: {
			get () {
				return this.$store.state.selectedStall
			},
			set (stall) {
				this.$store.commit('setSelectedStall', stall)
			}
		},
		isSelectedStallActive () {
			return this.isStallActive(this.selectedStall)
		},
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		}
	},
	beforeMount () {
		this.stall = this.stallProp
		this.getStallDetails()
	},
	mounted () {
		if (this.stall) {
			this.selectedStall = { ...this.stall.location }
		}
	},
	destroyed () {
		if (this.nearbyStalls.findIndex(s => s.id === parseInt(this.$f7route.params.id)) === -1) {
			this.$store.commit('setSelectedStall', this.cart.stall || this.nearbyStalls[0])
		}
	},
	methods: {
		getStallDetails (filters = {}) {
			filters = Object.assign({
				is_active: 1,
				location_id: this.$f7route.params.id
			}, filters)

			return this.$store.dispatch('getItems', filters).then((response) => {
				if (response.code === 200) {
					this.items.data = filters.page ? this.items.data.concat(response.data.items) : response.data.items
					this.items.pagination = response.data.pagination
					this.$nextTick(() => {
						if (this.$refs.page) {
							this.$f7.lazy.create(this.$refs.page.$el)
						}
					})
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss">
	#stall-details {
		.page-content {
			padding-top: 200px;
		}
	}
</style>

const actions = {
	nuxtServerInit ({ commit }, { req }) {
		const location = req.session.location || null

		if (location && location.address) {
			location.line = location.address
			delete location.address
			req.session.location = location
		}

		commit('setIsLoggedIn', !!req.session.isLoggedIn)
		commit('setUser', req.session.user || null)
		commit('setLocation', location)
	},
	setSentryScope (ctx) {
		if (ctx.state.user) {
			this.$sentry.configureScope((scope) => {
				scope.setUser({
					id: ctx.state.user.id,
					name: ctx.state.user.name
				})
			})
		}

		if (ctx.state.location) {
			this.$sentry.configureScope((scope) => {
				scope.setExtra('location', ctx.state.location)
			})
		}

		if (ctx.state.selectedStall) {
			this.$sentry.configureScope((scope) => {
				scope.setExtra('stall', {
					id: ctx.state.selectedStall.id,
					name: ctx.state.selectedStall.name
				})
			})
		}
	},
	register (ctx, data) {
		return this.$axios.$post('/api/auth/register', data).then((response) => {
			if (response.code === 201) {
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	sendSms (ctx, data) {
		return this.$axios.$post('/api/utils/sms/send', data)
	},
	sendEmail (ctx, data) {
		return this.$axios.$post('/api/auth/email/send', data)
	},
	sendOtp (ctx, data) {
		return this.$axios.$post('/api/auth/otp/send', data)
	},
	verifyOtp (ctx, data) {
		return this.$axios.$post('/api/auth/otp/verify', data).then((response) => {
			if (response.code === 200) {
				ctx.commit('setIsLoggedIn', true)
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	verifyMobile (ctx, data) {
		return this.$axios.$post('/api/auth/mobile/verify', data)
	},
	login (ctx, data) {
		return this.$axios.$post('/api/auth/login', data).then((response) => {
			if (response.code === 200 && response.data.info.is_phone_verified) {
				ctx.commit('setIsLoggedIn', true)
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	logout () {
		return this.$axios.$get('/api/auth/logout')
	},
	forgotPassword (ctx, data) {
		return this.$axios.$post('/api/auth/password/forgot', data)
	},
	resetPassword (ctx, data) {
		return this.$axios.$post('/api/auth/password/reset', data)
	},
	changePassword (ctx, data) {
		return this.$axios.$post('/api/auth/password/change', data)
	},
	changeMobile (ctx, data) {
		return this.$axios.$post('/api/auth/mobile/change', data)
	},
	changeEmail (ctx, data) {
		return this.$axios.$post('/api/auth/email/change', data)
	},
	getPlaces (ctx, data) {
		return this.$axios.$get('/api/maps/places', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				components: 'country:in',
				location: '10.995998,78.366526',
				radius: 346837.58,
				strictbounds: true
			}, data)
		})
	},
	getPlace (ctx, data) {
		return this.$axios.$get('/api/maps/place', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				fields: 'formatted_address,address_component,geometry',
				save: data.save
			}, data)
		})
	},
	getPlaceByGeocode (ctx, data) {
		return this.$axios.$get('/api/maps/geocode', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				save: data.save
			}, data)
		})
	},
	getNearbyStalls (ctx) {
		if (ctx.state.location) {
			return this.$axios.$get('/api/locations', {
				params: {
					lat: ctx.state.location.lat,
					lng: ctx.state.location.lng,
					location_type: 'stall',
					is_active: 1
				}
			}).then((response) => {
				if (response.code === 200) {
					const previousStallId = ctx.state.selectedStall ? ctx.state.selectedStall.id : null
					const selectedStallIndex = response.data.locations.findIndex(l => l.id === previousStallId)

					ctx.commit('setNearbyStalls', response.data.locations)

					if (!ctx.state.selectedStall || selectedStallIndex === -1) {
						ctx.commit('setSelectedStall', response.data.locations[0] || null)

						if (!ctx.state.selectedStall || ctx.state.selectedStall.id !== previousStallId) {
							ctx.commit('resetCart')
						}
					}
				}

				return response
			})
		}

		return Promise.reject(new Error('Location not set'))
	},
	getItems (ctx, filters = {}) {
		return this.$axios.$get('/api/items', {
			params: {
				location_id: ctx.state.selectedStall ? ctx.state.selectedStall.id : null,
				...filters
			}
		})
	},
	cartCalculation (ctx) {
		let cartWeight = 0
		let leftOverDiscountRate = 0
		let cartItems = JSON.parse(JSON.stringify(ctx.state.cart.items))
		const price = {
			subTotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: {},
			discount: 0,
			discountedAmount: 0,
			discountedTax: 0,
			discounts: [],
			deliveryFee: parseFloat(ctx.state.settings.delivery_fee || 0),
			roundOff: 0,
			total: 0
		}

		cartItems = cartItems.map((item) => {
			let tax = 0
			const inclusiveTaxes = []
			const exclusiveTaxes = []
			let inclusivePercent = 0
			let exclusivePercent = 0
			const total = item.inventory.price * item.quantity

			cartWeight += item.weight * item.quantity
			item.subTotal = total
			item.discountedAmount = 0
			item.discountedTax = 0
			item.discounts = []

			item.inventory.taxes.forEach((tax) => {
				const taxData = {
					tax_id: tax.id,
					tax_name: tax.name,
					tax_rate: tax.rate
				}

				if (tax.inclusion_type === 'inclusive') {
					inclusiveTaxes.push(taxData)
					inclusivePercent += tax.rate
				} else {
					taxData.amount = total
					taxData.tax_amount = total * (tax.rate / 100)
					exclusiveTaxes.push(taxData)
					exclusivePercent += tax.rate
				}
			})

			if (exclusivePercent > 0) {
				price.subTotal += total
				tax = total * (exclusivePercent / 100)
				price.exclusiveTaxTotal += tax
				item.taxType = 'exclusive'
				item.taxes = exclusiveTaxes
			} else if (inclusivePercent > 0) {
				const subTotal = total - total * (inclusivePercent / (100 + inclusivePercent))

				item.subTotal = subTotal
				price.subTotal += subTotal
				tax = total * (inclusivePercent / (100 + inclusivePercent))
				price.inclusiveTaxTotal += tax
				item.taxType = 'inclusive'
				item.taxes = inclusiveTaxes.map((tax) => {
					return Object.assign({}, tax, {
						amount: subTotal,
						tax_amount: subTotal * (tax.tax_rate / 100)
					})
				})
			} else {
				price.subTotal += total
				item.taxes = []
			}

			item.taxAmount = tax
			price.tax += tax

			if (item.taxes) {
				item.taxes.forEach((tax) => {
					tax = Object.assign({}, tax)

					if (price.taxes[tax.tax_id]) {
						price.taxes[tax.tax_id].tax_amount += tax.tax_amount
					} else {
						price.taxes[tax.tax_id] = tax
					}
				})
			}

			return item
		})

		if (ctx.state.eligibleDiscounts.length) {
			if (this.app.validateDiscount(ctx.state.eligibleDiscounts[0], {
				items: cartItems,
				price
			}, ctx.state.user) && ctx.state.eligibleDiscounts[0].discount_entity.short_name === 'shipping') {
				if (ctx.state.eligibleDiscounts[0].discount_type === 'percentage') {
					price.discount = price.deliveryFee * (
						Math.abs(ctx.state.eligibleDiscounts[0].discount_value) / 100
					)
				} else if (ctx.state.eligibleDiscounts[0].discount_type === 'fixed') {
					price.discount = Math.abs(ctx.state.eligibleDiscounts[0].discount_value)
				}
			} else {
				cartItems = cartItems.map((item) => {
					let discountedAmount = 0
					let discountedTax = 0
					let taxes = []
					let discountAmountRate = 0

					ctx.state.eligibleDiscounts.forEach((discount) => {
						const discountValue = Math.abs(discount.discount_value)

						if (this.app.validateDiscount(discount, {
							items: cartItems,
							price
						}, ctx.state.user) && (
							discount.items.length === 0 || discount.items.map(i => i.id).includes(item.id)
						) && (
							discount.categories.length === 0 || discount.categories.includes(item.category.id)
						) && (!discount.max_discount_amount || (price.discount < discount.max_discount_amount))) {
							if (discount.discount_type === 'percentage') {
								discountedAmount += item.subTotal > 0
									? (
										(item.subTotal / item.quantity) * (discount.range.item_qty || item.quantity)
									) * (discountValue / 100)
									: 0
								discountedTax += item.taxAmount > 0
									? (
										(item.taxAmount / item.quantity) * (discount.range.item_qty || item.quantity)
									) * (discountValue / 100)
									: 0
								taxes = item.taxes.map((tax) => {
									tax.discounted_tax = tax.tax_amount > 0
										? (
											(tax.tax_amount / item.quantity) * (discount.range.item_qty || item.quantity)
										) * (discountValue / 100)
										: 0
									price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

									return tax
								})
							} else if (discount.discount_type === 'fixed') {
								const itemTotal = item.subTotal + item.taxAmount

								if (discount.items.length || discount.categories.length) {
									discountedAmount = (item.subTotal / itemTotal) * discountValue
									discountedTax = (item.taxAmount / itemTotal) * discountValue
									taxes = item.taxes.map((tax) => {
										tax.discounted_tax = (tax.tax_amount / item.taxAmount) * discountedTax
										price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

										return tax
									})
								} else {
									const total = price.subTotal + price.tax

									if (discountValue < total) {
										discountAmountRate = (itemTotal / total) + leftOverDiscountRate
										const totalDiscountedAmount = discountValue * discountAmountRate

										discountedAmount += (item.subTotal / itemTotal) * totalDiscountedAmount
										discountedTax += (item.taxAmount / itemTotal) * totalDiscountedAmount
										taxes = item.taxes.map((tax) => {
											tax.discounted_tax = (tax.tax_amount / item.taxAmount) * discountedTax
											price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

											return tax
										})
									} else {
										discountedAmount += item.subTotal
										discountedTax += item.taxAmount
										taxes = item.taxes.map((tax) => {
											tax.discounted_tax = tax.tax_amount
											price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

											return tax
										})
									}
								}
							}

							const totalDiscount = price.discount + discountedAmount + discountedTax

							if (discount.max_discount_amount > 0 && (totalDiscount > discount.max_discount_amount)) {
								const discountDiff = totalDiscount - discount.max_discount_amount

								discountedAmount -= discountDiff * (discountedAmount / (discountedAmount + discountedTax))
								discountedTax -= discountDiff * (discountedTax / (discountedAmount + discountedTax))
							}

							if ((item.discountedAmount + item.discountedTax) < (item.subTotal + item.taxAmount)) {
								item.discounts.push({
									...discount,
									discounted_amount: discountedAmount,
									discounted_tax: discountedTax
								})

								item.discountedAmount += discountedAmount
								item.discountedTax += discountedTax
								item.taxes = taxes
								price.discountedAmount += discountedAmount
								price.discountedTax += discountedTax
								price.discount += discountedAmount + discountedTax

								if (leftOverDiscountRate) {
									leftOverDiscountRate = 0
								}
							} else if (discount.discount_type === 'fixed' && discount.items.length === 0) {
								leftOverDiscountRate += discountAmountRate
							}
						}
					})

					return item
				})
			}
		}

		if (price.subTotal <= 0) {
			price.deliveryFee = 0
		}

		price.total = ((price.subTotal + price.tax) - price.discount) + price.deliveryFee
		price.taxes = Object.values(price.taxes)
		ctx.commit('setCart', {
			stall: ctx.state.cart.stall,
			items: cartItems,
			weight: parseFloat(cartWeight.toFixed(3)),
			price
		})
	},
	getAddresses (ctx, data = null) {
		return this.$axios.$get('/api/addresses', {
			params: data
		}).then((response) => {
			if (response.code === 200) {
				ctx.commit('setAddresses', response.data.customer_addresses)
			}

			return response
		})
	},
	getPaymentMethods () {
		return this.$axios.$get('/api/payments/methods')
	},
	saveAddress (ctx, address) {
		let url = '/api/addresses'

		if (address.id) {
			url += `/${address.id}`
		}

		return this.$axios[address.id ? '$put' : '$post'](url, address)
	},
	deleteAddress (ctx, id) {
		return this.$axios.$delete(`/api/addresses/${id}`)
	},
	completeOrder (ctx) {
		const date = new Date()
		const customAttributes = {}

		if (ctx.state.paymentMethod.slug !== 'cod') {
			customAttributes.pg_timestamp = ctx.state.paymentMethod.time
		}

		const order = {
			location_id: ctx.state.cart.stall.id,
			receipt_code: `${ctx.state.user.id}${ctx.state.cart.stall.id}${date.valueOf()}`,
			payment_method: ctx.state.paymentMethod.slug,
			order_status: ctx.state.paymentMethod.slug === 'cod' ? 'cod_authorized' : 'pending',
			customer_email: ctx.state.user.email,
			customer_name: ctx.state.user.name,
			customer_mobile: ctx.state.user.phone,
			customer_note: '',
			shipping_address: ctx.state.deliveryAddress.address,
			billing_address: ctx.state.deliveryAddress.address,
			sub_total: ctx.state.cart.price.subTotal,
			tax: ctx.state.cart.price.tax,
			delivery_fee: ctx.state.cart.price.deliveryFee,
			discount_id: ctx.state.eligibleDiscounts.length ? ctx.state.eligibleDiscounts[0].id : null,
			discount_name: ctx.state.eligibleDiscounts.length ? ctx.state.eligibleDiscounts[0].title : null,
			discount_code: ctx.state.eligibleDiscounts.length ? ctx.state.eligibleDiscounts[0].code : null,
			discount: ctx.state.cart.price.discount,
			total: ctx.state.cart.price.total,
			order_type: 'online',
			order_date: this.$moment(date).utc().format('YYYY-MM-DD HH:mm:ss'),
			custom_attributes: customAttributes,
			items: ctx.state.cart.items.map((item, index) => {
				const discount = item.discountedAmount + item.discountedTax

				return {
					item_code: (index + 1).toString(),
					item_details: {
						item_name: item.name,
						parent_item_name: item.parent.name || ''
					},
					item_id: item.id,
					parent_item_id: item.parent.id || null,
					item_inventory_id: item.inventory.id,
					qty: item.quantity,
					unit_cost: item.inventory.price,
					sub_total: item.subTotal,
					tax: item.taxAmount,
					taxes: item.taxes,
					discount,
					total: item.subTotal + item.taxAmount - discount
				}
			})
		}

		return this.$axios.$post('/api/orders', order)
	},
	getOrders (ctx, data) {
		return this.$axios.$get('/api/orders', {
			params: data
		})
	},
	getOrderDetails (ctx, id) {
		return this.$axios.$get(`/api/orders/${id}`)
	},
	addToFavourites (ctx, itemId) {
		return this.$axios.$post('/api/items/favourites', {
			item_id: itemId
		})
	},
	removeFavourite (ctx, itemId) {
		return this.$axios.$delete(`/api/items/favourites/${itemId}`)
	},
	getItemStalls (ctx, itemId) {
		return this.$axios.$get('/api/items/inventories', {
			params: {
				item_id: itemId,
				location_type: 'stall'
			}
		})
	},
	updateProfile (ctx, data) {
		return this.$axios.$patch('/api/auth/profile', data).then((response) => {
			if (response.code === 200) {
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	getMaster (ctx, data) {
		return this.$axios.$get('/api/masters', {
			params: data
		})
	},
	createConverstion (ctx, data) {
		return this.$axios.$post('/api/conversations', data)
	},
	getMessages (ctx, data) {
		return this.$axios.$get(`/api/conversations/${data.conversation_id}/messages`, {
			params: data
		})
	},
	createMessage (ctx, data) {
		return this.$axios.$post(`/api/conversations/${data.conversation_id}/messages`, data)
	},
	getPages (ctx, data) {
		return this.$axios.$get('/api/pages', {
			params: Object.assign({}, {
				is_active: 1
			}, data)
		})
	},
	getDiscounts (ctx, data) {
		return this.$axios.$get('/api/discounts', {
			params: data
		})
	},
	applyCoupon (ctx, data) {
		return this.$axios.$post('/api/discounts/apply', data)
	},
	generatePgRequest (ctx, data) {
		return this.$axios.$post('/api/payments/wl/generate', data)
	},
	checkPaymentStatus () {
		return this.$axios.$get('/api/payments/wl/status')
	},
	checkPgStatus (ctx, data) {
		return this.$axios.$post('/api/payments/wl/pg/status', data)
	},
	removeDiscount (ctx) {
		ctx.commit('setEligibleDiscounts', ctx.state.eligibleDiscounts.filter(d => !d.code))
		ctx.dispatch('cartCalculation')
	},
	checkDeliveryAvailability (ctx, data) {
		return this.$axios.$get('/api/addresses/delivery-availability', {
			params: data
		})
	},
	getDeliveryDetails (ctx, data) {
		return this.$axios.$get('/api/delivery/order-delivery-status', {
			params: data
		})
	},
	submitFeedback (ctx, data) {
		return this.$axios.$post('/api/orders/feedback', data)
	}
}

export default actions

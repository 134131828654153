<template>
	<f7-app :params="f7Params">
		<f7-panel left cover>
			<f7-page id="menu">
				<div class="header p-3">
					<div class="text-align-center mb-3">
						<f7-icon f7="person_circle" size="100px" />
					</div>
					<f7-row class="align-items-center">
						<f7-col v-if="isLoggedIn" width="80">
							<p class="word-wrap m-0">
								{{ user.name }}
							</p>
							<p class="word-wrap m-0">
								{{ user.email }}
							</p>
						</f7-col>
						<f7-col :width="isLoggedIn ? 20 : 100" :class="!isLoggedIn ? 'text-align-center' : ''">
							<f7-link
								v-if="!isLoggedIn"
								href="/login"
								text="SIGN IN"
								class="text-color-white font-weight-bold"
								panel-close
								reload-all
							/>
							<f7-link
								v-else
								href="/settings/edit-profile"
								text="EDIT"
								class="float-right text-color-white font-weight-bold"
								panel-close
							/>
						</f7-col>
					</f7-row>
				</div>
				<f7-list class="mt-0" no-hairlines no-hairlines-between>
					<f7-list-item link="/home" title="Home" panel-close>
						<f7-icon slot="media" f7="house" color="gray" />
					</f7-list-item>
					<f7-list-item v-if="isLoggedIn" link="/items?type=favourites" title="Favourites" panel-close>
						<f7-icon slot="media" f7="square_favorites_alt" color="gray" />
					</f7-list-item>
					<f7-list-item v-if="isLoggedIn" link="/addresses" title="Manage Address" panel-close>
						<f7-icon slot="media" f7="placemark" color="gray" />
					</f7-list-item>
					<f7-list-item v-if="isLoggedIn" link="/orders" title="Order History" panel-close>
						<f7-icon slot="media" f7="square_list" color="gray" />
					</f7-list-item>
					<f7-list-item v-if="isLoggedIn" link="/settings" title="Settings" panel-close>
						<f7-icon slot="media" f7="gear_alt" color="gray" />
					</f7-list-item>
					<f7-list-item link="/about" title="About" no-chevron panel-close>
						<f7-icon slot="media" f7="info_circle" color="gray" />
					</f7-list-item>
					<f7-list-item
						v-if="isLoggedIn"
						link="#"
						title="Logout"
						no-chevron
						panel-close
						@click="logout"
					>
						<f7-icon slot="media" f7="power" color="gray" />
					</f7-list-item>
				</f7-list>
			</f7-page>
		</f7-panel>
		<f7-view main :url="mainUrl" />
	</f7-app>
</template>

<script>
export default {
	asyncData (ctx) {
		return ctx.$axios.$get('/api/settings').then((response) => {
			if (response.code === 200) {
				ctx.store.commit('setState', {
					key: 'settings',
					value: Object.assign({}, ctx.store.state.settings, response.data.settings)
				})
			}
		}).catch((err) => {
			console.error(err) // eslint-disable-line
		})
	},
	data () {
		return {
			f7Params: {
				id: 'com.meengal.tnfdc',
				name: 'Meengal',
				routes: this.$routes,
				theme: 'md'
			},
			mainUrl: '/intro'
		}
	},
	computed: {
		isLoggedIn () {
			return this.$store.state.isLoggedIn
		},
		user () {
			return this.$store.state.user
		},
		location () {
			return this.$store.state.location
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		}
	},
	watch: {
		processing (value) {
			this.$f7.preloader[value ? 'show' : 'hide']()
		}
	},
	beforeMount () {
		this.$store.dispatch('setSentryScope')

		if (this.isLoggedIn || this.location) {
			this.mainUrl = '/home'
		}
	},
	mounted () {
		if (!this.isCookieEnabled()) {
			return this.$f7.dialog.create({
				title: 'Cookies Required',
				text: 'Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue.',
				buttons: [{
					text: 'RETRY',
					onClick () {
						window.location.reload()
					}
				}]
			}).open()
		}

		window.back = () => {
			if (!this.$$('.view').hasClass('router-transition')) {
				const backLink = this.$$('.link.back')

				if (this.$f7.panel.get().opened) {
					this.$f7.panel.close()
				} else if (this.$$('.modal-in').length > 0) {
					this.$f7.popup.close()
					this.$f7.dialog.close()
					this.$f7.popover.close()
					this.$f7.actions.close()
				} else if (['intro', 'home'].includes(this.$f7.views.main.router.currentRoute.name)) {
					this.$bridge.minimize()
				} else if (backLink.length) {
					backLink.click()
				} else {
					this.$f7.views.main.router.back()
				}
			}
		}

		this.$f7.on('touchend:active', this.lazyLoad)
		this.$f7.views.main.router.on('routeChanged', () => {
			const view = this.$$('.view')

			if (view.hasClass('router-transition')) {
				view.removeClass(
					'router-transition router-transition-forward router-transition-backward'
				)
			}
		})
	},
	methods: {
		logout () {
			this.$f7.dialog.confirm('Are you sure you want to logout?', 'Logout', () => {
				this.processing = true
				this.$store.dispatch('logout').then(() => {
					setTimeout(() => {
						this.processing = false
						this.$f7.views.main.router.navigate('/login', {
							clearPreviousHistory: true
						})
					}, 2000)
				}).catch((err) => {
					this.showErrors(err)
					this.processing = false
				})
			})
		},
		lazyLoad () {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.$f7.$('.lazy').each((i, e) => {
					if (this.$f7.lazy.isInViewport(e)) {
						this.$f7.lazy.loadImage(e)
					}
				})
			}, 200)
		}
	}
}
</script>

<style lang="scss" scoped>
	#menu {
		background: white;

		.header {
			position: relative;
			color: white;
			background: linear-gradient(to bottom, #39C0EE, #0AA4DA);
		}
	}
</style>

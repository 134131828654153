<template>
	<f7-page
		id="payments"
		ptr
		:ptr-distance="60"
		@ptr:refresh="refresh"
	>
		<f7-navbar back-link="Back" back-link-force>
			<f7-nav-title style="line-height: 1">
				<p class="m-0">
					Payment
				</p>
				<small style="font-size: 14px">
					{{ cart.items.length }} Item{{ cart.items.length === 1 ? '' : 's' }}, To pay: {{ cart.price.total | currency }}
				</small>
			</f7-nav-title>
		</f7-navbar>
		<f7-block-title v-if="loading || paymentMethods.length" class="m-3">
			Payment Methods
		</f7-block-title>
		<f7-list v-if="loading" class="skeleton-text skeleton-effect-blink mb-0">
			<f7-list-item v-for="i in 4" :key="`payment-method-${i}`" title="Payment Method" link="#" />
		</f7-list>
		<f7-list v-else-if="paymentMethods.length" class="mb-0">
			<f7-list-item
				v-for="method in paymentMethods"
				:key="method.id"
				:title="method.name"
				link="#"
				:disabled="method.slug === 'cod' && cart.price.total >= 1000"
				@click="initPayment(method.slug)"
			/>
		</f7-list>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				No payment methods found!
			</h2>
		</div>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			loading: true,
			orderId: null,
			paymentMethods: []
		}
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'processing',
					value
				})
			}
		},
		cart () {
			return this.$store.state.cart
		},
		deliveryAddress () {
			return this.$store.state.deliveryAddress
		},
		paymentMethod: {
			get () {
				return this.$store.state.paymentMethod
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'paymentMethod',
					value
				})
			}
		}
	},
	watch: {
		cart (cart) {
			if (cart.items.find(i => i.stockAdjusted)) {
				this.$f7router.back()
			}
		}
	},
	mounted () {
		this.getPaymentMethods()
	},
	methods: {
		getPaymentMethods () {
			return this.$store.dispatch('getPaymentMethods').then((response) => {
				if (response.code === 200) {
					this.paymentMethods = response.data.payment_methods
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		initPayment (slug) {
			this.processing = true
			this.paymentMethod = { slug, time: (new Date()).getTime() }

			this.$store.dispatch('checkDeliveryAvailability', {
				location_id: this.cart.stall.id,
				lat: this.deliveryAddress.address.lat,
				lng: this.deliveryAddress.address.lng,
				pincode: this.deliveryAddress.address.pincode
			}).then((response) => {
				if (response.code === 200) {
					return this.$store.dispatch('completeOrder')
				}

				const err = new Error()
				err.response = response

				return Promise.reject(err)
			}).then((response) => {
				this.processing = false

				if (response.code === 201) {
					this.orderId = response.data.orders.id

					switch (slug) {
					case 'world_line':
						this.$f7router.navigate(`/payments/pg?order_id=${this.orderId}`)
						break
					default:
						this.$store.dispatch('sendSms', {
							phone: this.user.phone,
							message: `Your Meengal order no. #${this.orderId} for Rs. ${this.cart.price.total}. Track your order here: https://meengal.com/o/t/${this.orderId}`
						})

						this.$f7router.navigate(`/orders/${this.orderId}`, { clearPreviousHistory: true })
						this.$store.commit('resetCart')
						this.$store.commit('setPaymentMethod', null)
						this.$store.commit('setDeliveryAddress', null)
						this.$store.commit('setEligibleDiscounts', [])
					}
				}
			}).catch((err) => {
				this.processing = false
				this.showErrors(err)
			})
		}
	}
}
</script>

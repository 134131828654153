<template>
	<f7-page id="order-tracking" :ptr="true" :ptr-distance="60" @ptr:refresh="refresh">
		<f7-navbar
			back-link="Back"
			back-link-force
			@click:back="$f7router.back(`/orders/${$f7route.params.id}`)"
		>
			<f7-nav-title style="line-height: 1">
				<p class="m-0">
					Order# {{ $f7route.params.id }}
				</p>
				<small v-if="!loading && delivery" class="text-capitalize" style="font-size: 14px">
					Delivery Status: {{ delivery.state.split('_').join(' ') }}
				</small>
			</f7-nav-title>
		</f7-navbar>
		<div v-if="loading" class="skeleton-text skeleton-effect-blink">
			<div class="skeleton-block" style="height: 40vh" />
			<div class="timeline">
				<div v-for="i in 3" :key="i" class="timeline-item">
					<div class="timeline-item-date">
						xx xxx
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-time">
							xxxxxxx
						</div>
						<div class="timeline-item-title">
							xxxxx
						</div>
						<div class="timeline-item-text">
							xxxxx xxxxx xxxxx xxxxx
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="delivery">
			<google-map
				:map-config="mapConfig"
				:markers="markers"
				:polylines="polylines"
			/>
			<f7-list v-if="!['placed', 'delivered'].includes(delivery.state)" class="my-0">
				<f7-list-item
					no-chevron
					link="#"
					@click="$bridge.openDialer(delivery.runner.phone_number)"
				>
					<div slot="header" class="text-capitalize">
						Order {{
							delivery.state === 'at_store' ? 'Preparing' : delivery.state.split('_').join(' ')
						}}
					</div>
					<div v-if="delivery.runner.name" slot="title">
						{{ delivery.runner.name }}
						<span v-if="delivery.state === 'assigned'">will deliver your order</span>
						<span v-if="delivery.state === 'at_store'">has reached the store</span>
						<span v-if="delivery.state === 'pickedup'">has picked up your order</span>
					</div>
					<div v-if="delivery.state === 'pickedup'" slot="footer">
						Tasty fish is on your way!
					</div>
					<div v-if="delivery.runner.phone_number" slot="after">
						<f7-icon f7="phone_circle_fill" color="blue" />
					</div>
				</f7-list-item>
			</f7-list>
			<f7-block-title class="mt-3">
				Order Timeline
			</f7-block-title>
			<div class="timeline">
				<div
					v-for="(history, index) in delivery.status_histories.filter(h => h.visible)"
					:key="index"
					class="timeline-item"
				>
					<div class="timeline-item-date">
						{{ $moment.utc(history.changed_at).local().format('DD') }}
						<small>{{ $moment.utc(history.changed_at).local().format('MMM') }}</small>
					</div>
					<div class="timeline-item-divider" />
					<div class="timeline-item-content">
						<div class="timeline-item-time">
							{{ $moment.utc(history.changed_at).local().format('hh:mm A') }}
						</div>
						<div class="timeline-item-title text-capitalize">
							{{ history.state.split('_').join(' ') }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				Tracking details not found!
			</h2>
		</div>
	</f7-page>
</template>

<script>
import GoogleMap from '~/components/google-map'

export default {
	components: {
		GoogleMap
	},
	data () {
		return {
			socket: null,
			loading: true,
			mapConfig: {
				draggable: false,
				disableDefaultUI: true
			},
			markers: [],
			polylines: [],
			delivery: null,
			interval: null
		}
	},
	beforeMount () {
		this.getOrderDetails()
		this.initSocket()
	},
	destroyed () {
		clearInterval(this.interval)
		this.socket.close()
	},
	methods: {
		getOrderDetails () {
			this.$store.dispatch('getDeliveryDetails', {
				order_id: this.$f7route.params.id
			}).then((response) => {
				if (response.code === 200) {
					this.delivery = response.data
					this.updateLocation()
				}

				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		updateLocation () {
			this.markers = [
				{
					iconConfig: {
						url: `${process.env.DOMAIN}/images/store-marker.svg`,
						size: {
							x: 42,
							y: 42
						}
					},
					position: {
						lat: parseFloat(this.delivery.store_details.lat),
						lng: parseFloat(this.delivery.store_details.lng)
					}
				},
				{
					iconConfig: {
						url: `${process.env.DOMAIN}/images/home-marker.svg`,
						size: {
							x: 42,
							y: 42
						}
					},
					position: {
						lat: parseFloat(this.delivery.customer_details.lat),
						lng: parseFloat(this.delivery.customer_details.lng)
					}
				}
			]
			this.polylines = this.markers.map(m => m.position)

			if (this.delivery.runner.current_lat && this.delivery.runner.current_lng) {
				this.markers.push({
					iconConfig: {
						url: `${process.env.DOMAIN}/images/rider-marker.svg`,
						size: {
							x: 30,
							y: 30
						},
						anchor: {
							x: 0,
							y: 10
						}
					},
					position: {
						lat: parseFloat(this.delivery.runner.current_lat),
						lng: parseFloat(this.delivery.runner.current_lng)
					}
				})
			}
		},
		initSocket () {
			this.socket = new WebSocket(
				`${this.getSocketUrl(process.env.WEB_DOMAIN)}/orders/${this.$f7route.params.id}`
			)

			this.socket.onmessage = (event) => {
				try {
					const data = JSON.parse(event.data)

					if (data.type === 'status_change') {
						this.delivery = data.data
						this.updateLocation()
					}
				} catch (err) {
					console.error(err) // eslint-disable-line
				}
			}

			this.socket.onerror = err => console.error(err) // eslint-disable-line
			this.interval = setInterval(() => {
				if (this.socket.readyState) {
					this.socket.send(JSON.stringify({ type: 'heartbeat' }))
				}
			}, 10000)
		}
	}
}
</script>

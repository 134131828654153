<template>
	<f7-page
		id="items"
		ref="page"
		ptr
		:ptr-distance="60"
		infinite
		:infinite-distance="1"
		:infinite-preloader="items.pagination && items.pagination.current_page < items.pagination.last_page"
		@ptr:refresh="refresh"
		@infinite="loadMoreResults('items', 'getItems')"
	>
		<f7-navbar back-link="Back" back-link-force>
			<div slot="title" class="text-capitalize">
				{{ $f7router.currentRoute.query.type }}
			</div>
			<f7-nav-right>
				<f7-link class="searchbar-enable" data-searchbar=".searchbar" icon-f7="search" />
			</f7-nav-right>
			<f7-searchbar
				class="searchbar"
				placeholder="Search Item"
				expandable
				@input="searchItems"
			/>
		</f7-navbar>
		<items :loading="loading" :items="items.data" />
		<cart-toolbar />
	</f7-page>
</template>

<script>
import Items from '~/components/items'
import CartToolbar from '~/components/cart-toolbar'

export default {
	components: {
		Items,
		CartToolbar
	},
	data () {
		return {
			loading: true,
			items: {
				data: [],
				pagination: null
			},
			timer: null,
			searchTerm: null
		}
	},
	computed: {
		processing: {
			get () {
				return this.$store.state.processing
			},
			set (value) {
				this.$store.commit('setProcessing', value)
			}
		},
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		}
	},
	mounted () {
		this.getItems()

		if (this.$f7route.query.type === 'search items') {
			setTimeout(() => {
				this.$$('#items .searchbar-enable').click()
			}, 200)
		}
	},
	methods: {
		getItems (filters = {}) {
			filters = Object.assign({
				is_active: 1,
				query: this.searchTerm,
				only_favourite: this.$f7route.query.type === 'favourites' ? 1 : 0
			}, filters)

			return this.$store.dispatch('getItems', filters).then((response) => {
				this.loading = false

				if (response.code === 200) {
					this.items.data = filters.page ? this.items.data.concat(response.data.items) : response.data.items
					this.items.pagination = response.data.pagination
					this.$nextTick(() => {
						if (this.$refs.page) {
							this.$f7.lazy.create(this.$refs.page.$el)
						}
					})
				}
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		},
		searchItems ($event) {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.processing = true
				this.searchTerm = $event.target.value
				this.getItems().then(() => {
					this.processing = false
				})
			}, 500)
		}
	}
}
</script>

<template>
	<f7-page
		id="orders"
		ptr
		:ptr-distance="60"
		infinite
		:infinite-distance="1"
		:infinite-preloader="orders.pagination && orders.pagination.current_page < orders.pagination.last_page"
		@ptr:refresh="refresh"
		@infinite="loadMoreResults('orders', 'getOrders')"
	>
		<f7-navbar back-link="Back" @click:back="$f7router.back('/home')">
			<f7-nav-title>Order History</f7-nav-title>
		</f7-navbar>
		<div v-if="loading" class="p-3">
			<f7-card v-for="i in 4" :key="i" class="skeleton-text skeleton-effect-blink m-0 mb-3">
				<f7-card-content>
					<f7-row class="align-items-center mb-2">
						<f7-col width="70">
							<h4 class="m-0">
								xxxxx xxxxx
							</h4>
							<p class="m-0">
								xxxxx xxxxx xxxxx
							</p>
						</f7-col>
						<f7-col width="30">
							<h4 class="float-right m-0">
								xxxxx xxxxx
							</h4>
						</f7-col>
					</f7-row>
					<f7-row class="align-items-center">
						<f7-col width="70">
							<p class="m-0" style="line-height: 1">
								xxxxx xxxxx xxxxx xxxxx
							</p>
						</f7-col>
						<f7-col width="30">
							<h4 class="float-right m-0">
								xxxxx xxxxx
							</h4>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div v-else-if="orders.data.length > 0" class="p-3">
			<f7-card
				v-for="(order, index) in orders.data"
				:key="index"
				class="m-0"
				:class="{ 'mb-3': index < orders.data.length - 1 }"
				@click.native="$f7router.navigate(`/orders/${order.id}`)"
			>
				<f7-card-content>
					<f7-row class="align-items-center mb-2">
						<f7-col width="60">
							<h4 class="m-0">
								Order# {{ order.id }}
							</h4>
							<p class="text-color-gray m-0">
								{{
									$moment.utc(order.order_date).local().format('lll')
								}}
							</p>
						</f7-col>
						<f7-col width="40">
							<f7-badge color="green" class="float-right">
								{{
									[
										'payment_authorized',
										'cod_authorized',
										'confirmed',
										'runner_cancelled'
									].includes(order.order_status.short_name) ? 'Preparing' : order.order_status.name
								}}
							</f7-badge>
						</f7-col>
					</f7-row>
					<f7-row class="align-items-center">
						<f7-col width="70">
							<p class="text-color-gray m-0" style="line-height: 1">
								{{ order.items.length }} item{{ order.items.length > 1 ? 's' : '' }}
							</p>
						</f7-col>
						<f7-col width="30">
							<h4 class="float-right text-color-blue m-0">
								{{ order.total | currency }}
							</h4>
						</f7-col>
					</f7-row>
				</f7-card-content>
			</f7-card>
		</div>
		<div
			v-else
			class="h-100 display-flex justify-content-center align-items-center"
			style="flex-direction: column"
		>
			<img src="/images/dead-fish.svg">
			<h2>
				No orders found!
			</h2>
		</div>
	</f7-page>
</template>

<script>
export default {
	data () {
		return {
			loading: true,
			orders: {
				data: [],
				pagination: null
			}
		}
	},
	computed: {
		allowLoadMore: {
			get () {
				return this.$store.state.allowLoadMore
			},
			set (value) {
				this.$store.commit('setState', {
					key: 'allowLoadMore',
					value
				})
			}
		}
	},
	beforeMount () {
		this.getOrders()
	},
	methods: {
		getOrders (filters) {
			filters = Object.assign({
				order_by: 'id',
				dir: -1
			}, filters)

			return this.$store.dispatch('getOrders', filters).then((response) => {
				if (response.code === 200) {
					this.orders.data = filters.page ? this.orders.data.concat(response.data.orders) : response.data.orders
					this.orders.pagination = response.data.pagination
				}

				this.loading = false
			}).catch((err) => {
				this.showErrors(err)
				this.loading = false
			})
		}
	}
}
</script>

<template>
	<f7-toolbar
		v-if="cart.items.length > 0"
		class="bg-color-blue"
		bottom-md
		@click.native="$f7router.navigate('/cart')"
	>
		<f7-row class="w-100 align-items-center px-3" style="line-height: 1">
			<f7-col width="60">
				<p class="text-color-white m-0">
					{{ cart.items.length }} Item{{ cart.items.length > 1 ? 's' : '' }} | {{
						cart.weight | currency({
							symbol: '',
							fractionCount: 3
						})
					}} KG | {{
						cart.price.subTotal | currency
					}}
				</p>
				<small class="text-color-white">Extra charges may apply</small>
			</f7-col>
			<f7-col width="40">
				<div class="display-flex justify-content-flex-end align-items-center">
					<p class="text-color-white my-0 mr-2">
						VIEW CART
					</p>
					<f7-icon f7="cart" size="22" color="white" />
				</div>
			</f7-col>
			</div>
		</f7-row>
	</f7-toolbar>
</template>

<script>
export default {
	computed: {
		cart () {
			return this.$store.state.cart
		}
	}
}
</script>

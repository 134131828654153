let cartFullAlert = null

const loadScript = (url, cb) => {
	const script = document.createElement('script')
	script.type = 'text/javascript'

	if (script.readyState) { // IE
		script.onreadystatechange = function () {
			if (script.readyState === 'loaded' || script.readyState === 'complete') {
				script.onreadystatechange = null

				if (typeof cb === 'function') {
					cb()
				}
			}
		}
	} else { // Others
		script.onload = function () {
			if (typeof cb === 'function') {
				cb()
			}
		}
	}

	script.src = url
	document.getElementsByTagName('head')[0].appendChild(script)
}

const isCookieEnabled = () => {
	if (navigator.cookieEnabled) {
		return true
	}

	document.cookie = 'cookietest=1'
	const result = document.cookie.includes('cookietest=')
	document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'

	return result
}

export default {
	isCookieEnabled,
	getLocalStorage (key) {
		return (isCookieEnabled() ? localStorage.getItem(key) : null)
	},
	setLocalStorage (key, value) {
		if (isCookieEnabled()) {
			localStorage.setItem(key, value)
		}
	},
	removeLocalStorage (key) {
		if (isCookieEnabled()) {
			localStorage.removeItem(key)
		}
	},
	showErrors (err) {
		if (this.$refs.validator && err.response && err.response.status === 422 &&
			Object.keys(err.response.data.errors).length) {
			const errors = {}

			for (const key in err.response.data.errors) {
				errors[key] = [err.response.data.errors[key]]
			}

			this.$refs.validator.setErrors(errors)
		} else {
			const errMsg = 'Oops! Something went wrong, Try again later.'

			console.error(err) // eslint-disable-line
			this.$f7.toast.create({
				text: err.response ? (err.response.data.message || errMsg) : errMsg,
				closeTimeout: 3000
			}).open()
		}
	},
	refresh (cb) {
		this.$f7router.refreshPage()

		if (typeof cb === 'function') {
			cb()
		}
	},
	calculateDistance (lat1, lng1, lat2, lng2) {
		if ((lat1 === lat2) && (lng1 === lng2)) {
			return 0
		} else {
			const radlat1 = Math.PI * lat1 / 180
			const radlat2 = Math.PI * lat2 / 180
			const theta = lng1 - lng2
			const radtheta = Math.PI * theta / 180
			let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)

			if (dist > 1) {
				dist = 1
			}

			dist = Math.acos(dist)
			dist = dist * 180 / Math.PI
			dist = dist * 60 * 1.1515
			dist = dist * 1.609344

			return dist
		}
	},
	focusInput (querySelector) {
		setTimeout(() => this.$$(querySelector).eq(0).focus(), 500)
	},
	lazyLoad () {
		this.$$('.lazy').each((i, e) => {
			if (this.$f7.lazy.isInViewport(e)) {
				this.$f7.lazy.loadImage(e)
			}
		})
	},
	initFacebookSdk () {
		if (!window.FB) {
			window.fbAsyncInit = function () {
				window.FB.init({
					appId: process.env.FACEBOOK_APP_ID,
					cookie: true,
					xfbml: true,
					version: 'v7.0'
				})
			}

			loadScript('//connect.facebook.net/en_US/sdk.js')
		}
	},
	initGoogleSdk () {
		if (!window.gapi) {
			loadScript('//apis.google.com/js/platform.js', () => {
				window.gapi.load('auth2', () => {
					window.gapi.auth2.init({
						client_id: `${process.env.GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
						fetch_basic_profile: true,
						prompt: 'consent'
					})
				})
			})
		}
	},
	passwordComplexityMsg () {
		return `The password must contain at least,<br>
			8 characters minimum<br>
			1 uppercase letter (A-Z)<br>
			1 lowercase letter (a-z)<br>
			1 number (0-9)<br>
			1 special character (!@#$%^&*())
		`
	},
	showTooltip ($event) {
		this.$f7.tooltip.create({
			targetEl: $event.target,
			text: $event.target.getAttribute('data-tooltip')
		}).show()
	},
	hideTooltip ($event) {
		this.$f7.tooltip.destroy($event.target)
	},
	loadMoreResults (entity, functionName) {
		if (!this.allowLoadMore) {
			return
		}

		if (this[entity].pagination && this[entity].pagination.current_page < this[entity].pagination.last_page) {
			this.allowLoadMore = false
			this[functionName]({
				page: this[entity].pagination.current_page + 1
			}).then(() => {
				this.allowLoadMore = true
			})
		}
	},
	validateDiscount (discount, cart, user) {
		const cartItemQuantities = {}
		const cartCategoryQuantites = {}
		const cartItemIds = cart.items.map((i) => {
			if (cartCategoryQuantites[i.category.id]) {
				cartCategoryQuantites[i.category.id] += i.quantity
			} else {
				cartCategoryQuantites[i.category.id] = i.quantity
			}

			cartItemQuantities[i.id] = i.quantity

			return i.id
		})

		for (const key in discount.range) {
			const expression = `${
				Object.keys(discount.range[key])[0]
			} ${
				Object.values(discount.range[key])[0]
			}`

			switch (key) {
			case 'subtotal':
				// eslint-disable-next-line
				if (!eval(`${cart.price.subTotal} ${expression}`)) {
					return false
				}

				break
			case 'qty':
				// eslint-disable-next-line
				if (!eval(`${cart.items.length} ${expression}`)) {
					return false
				}

				break
			case 'prerequisite_qty':
				if (discount.prerequisite_categories.length && !discount.prerequisite_categories.every((i) => {
					// eslint-disable-next-line
					return eval(`${cartCategoryQuantites[i]} ${expression}`)
				})) {
					return false
				}

				if (discount.prerequisite_items.length && !discount.prerequisite_items.every((i) => {
					// eslint-disable-next-line
					return eval(`${cartItemQuantities[i]} ${expression}`)
				})) {
					return false
				}

				break
			}
		}

		if (discount.prerequisite_categories.length) {
			const cartItemCategoryIds = cart.items.map(i => i.category.id)

			if (!discount.prerequisite_categories.every(i => cartItemCategoryIds.includes(i))) {
				return false
			}
		}

		if (discount.prerequisite_items.length && !discount.prerequisite_items.every((i) => {
			return cartItemIds.includes(i)
		})) {
			return false
		}

		if (discount.customers.length && !discount.customers.includes(user.id)) {
			return false
		}

		if (discount.customer_groups.length && !discount.customer_groups.includes(user.customer_group_id)) {
			return false
		}

		return true
	},
	isStallActive (stall) {
		if (!stall.online) {
			return false
		}

		let result = false
		const now = this.$moment()
		const day = now.format('dddd').toLowerCase()

		if (stall.timings && stall.timings[day] && stall.timings[day].length) {
			for (const timing of stall.timings[day]) {
				const startTime = this.$moment(timing.start_time, 'HH:mm:ss')
				const endTime = this.$moment(timing.end_time, 'HH:mm:ss')

				if (now.isAfter(startTime) && now.isBefore(endTime)) {
					result = true
					break
				}
			}
		}

		return result
	},
	nextStallOpeningTime (stall) {
		const now = this.$moment()
		const day = now.format('dddd').toLowerCase()
		let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
		const dayIndex = days.indexOf(day)

		days = days.splice(dayIndex).concat([...days, day])

		for (let i = 0; i < days.length; i++) {
			if (stall.timings && stall.timings[days[i]] && stall.timings[days[i]].length) {
				for (const timing of stall.timings[days[i]]) {
					const timeArr = timing.start_time.split(':')

					now.set({ h: timeArr[0], m: timeArr[1] }).format('llll')

					if (now > this.$moment()) {
						return `Opens again ${now.calendar()}.`
					}
				}
			}

			now.add(1, 'days')
		}
	},
	getSocketUrl (url) {
		return url.replace('http', 'ws')
	},
	showCartFullAlert () {
		if (cartFullAlert) {
			cartFullAlert.destroy()
		}

		cartFullAlert = this.$f7.dialog.alert(`You can only order upto ${
			this.settings.order_weight_limit
		} KG of items per order.`, 'Alert')
	}
}
